import React from "react";
// import Footer from "../../components/Footer";
import Navbar from "../../components/Customer/Navbar";

const About = () => {
  return (
    <>
      <Navbar />
      {/* <Footer /> */}
    </>
  );
};

export default About;
