import React from 'react';
import "../../stylesheets/Homebody.css"

const MainHeader = () => {
    return (
        <>
            <section>
                <div className='parallax'>
                    {/* <h1 id="text" style={{color:"white", position:"relative"}}>AMAN</h1> */}
                </div>
            </section>
        </>
    );
}

export default MainHeader;