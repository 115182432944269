
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export var jsonArrayGroupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  
  function getItemNameFromObjectUsingCode(itemCode,itemsForCostingObjectArray){

    if(itemCode=="cmkey_boq_bom"){
      return "BOQ_BOM";
    }

    // console.log(
    //   "downloadCompleteData_getItemNameFromObjectUsingCode_itemCode",
    //   itemCode
    // );
    // console.log(
    //   "downloadCompleteData_getItemNameFromObjectUsingCode_itemsForCosting",
    //   itemsForCostingObjectArray
    // );
    let itemWithCode=itemsForCostingObjectArray.filter((itemForCostingObject)=>{

      return itemForCostingObject.code==itemCode
    });
    // console.log(
    //   "downloadCompleteData_getItemNameFromObjectUsingCode_itemWithCode",
    //   itemWithCode
    // );

    if(itemWithCode.length>0){
      return itemWithCode[0].name;
    }else{
      return "No name Found";
    }
  }

  export function processDataForGroupCalculation(itemsForCostingObjectArray) {
    
    /*
      DevNote[ToDo]:
                    1. Remove Parent Code and Code field(Done)
                    2. Show Total of each group in sheet end
      */
    // console.log(
    //   "downloadCompleteData_processDataForGroupCalculation_itemsForCostingObjectArray_before",
    //   itemsForCostingObjectArray
    // );

    // console.log(
    //   "downloadCompleteData_processDataForGroupCalculation_itemsForCosting",
    //   itemsForCosting
    // );

    Object.keys(itemsForCostingObjectArray).forEach(function(key) {
      
      let itemsForCostingArray = itemsForCostingObjectArray[key];
      // console.log(
      //   "downloadCompleteData_processDataForGroupCalculation_tempPartArray",
      //   itemsForCostingArray
      // );
      
      let totalNosOfGroup=0.00;
      let totalPriceOfGroup=0.00;
      let totalCostOfGroup=0.00;

      let itemsForCostingArrayTemp = itemsForCostingArray.map((itemForCostingObject,index) => {
        // console.log(
        //   "downloadCompleteData_processDataForGroupCalculation_itemForCostingObject",
        //   itemForCostingObject
        // );

        // totalNosOfGroup=totalNosOfGroup+itemForCostingObject.nos;//Old
        totalNosOfGroup=totalNosOfGroup+itemForCostingObject.Qty;

        console.log("processDataForGroupCalculation_itemForCostingObject",itemForCostingObject)

        // totalPriceOfGroup=totalPriceOfGroup+itemForCostingObject.priceCalculated;//Old
        totalPriceOfGroup=totalPriceOfGroup+itemForCostingObject.Rate;//

        // totalCostOfGroup=totalCostOfGroup+itemForCostingObject.costCalculated;//Old
        totalCostOfGroup=totalCostOfGroup+itemForCostingObject.Amount;
        
        // itemForCostingObject["SN"]=index+1

        Object.assign(itemForCostingObject, {"SN":index+1});


        delete itemForCostingObject["parentCode"];
        delete itemForCostingObject["code"];
        delete itemForCostingObject["price"];
        
        delete itemForCostingObject["nos"];
        delete itemForCostingObject["name"];
        delete itemForCostingObject["unit"];
        delete itemForCostingObject["remarks"];
        
        delete itemForCostingObject["priceCalculated"];
        delete itemForCostingObject["costCalculated"];

        return itemForCostingObject;
      });

      // itemsForCostingArrayTemp.push({name:"Total",nos:totalNosOfGroup,priceCalculated:totalPriceOfGroup,costCalculated:totalCostOfGroup});

      itemsForCostingArrayTemp.push({Name:"Total",Amount:totalCostOfGroup});
      // console.log(
      //   "downloadCompleteData_processDataForGroupCalculation_totalPriceOfGroup",
      //   totalPriceOfGroup
      // );

      // console.log(
      //   "downloadCompleteData_processDataForGroupCalculation_itemsForCostingArrayTemp",
      //   itemsForCostingArrayTemp
      // );

      itemsForCostingObjectArray[key] = itemsForCostingArrayTemp;
    });
    // console.log(
    //   "downloadCompleteData_processDataForGroupCalculation_itemsForCostingObjectArray_after",
    //   itemsForCostingObjectArray
    // );
    return itemsForCostingObjectArray;
  }

  export  function processDataForExcelExport(groupedItemsForCosting,costingSheetData,itemsForCosting) {
    /* create a new blank workbook */
    var workBook = XLSX.utils.book_new();

    Object.keys(groupedItemsForCosting).forEach(function(key) {
      /*
      console.log(
        "downloadCompleteData_itemsForCostingGroupByParentCode_key",
        key
      );
      console.log(
        "downloadCompleteData_itemsForCostingGroupByParentCode_value",
        itemsForCostingGroupByParentCode[key]
      );
      */
      /* create a worksheet for books */
      /*
        DevNote[ToDo]: put name/title of object in WorkBookSheet instead of key i.e. replace 'key' with 'name' in below line of code
        */
      var wsBooks = XLSX.utils.json_to_sheet(
        groupedItemsForCosting[key]
      );
      /* Add the worksheet to the workbook */
      // XLSX.utils.book_append_sheet(workBook, wsBooks, key);
      XLSX.utils.book_append_sheet(workBook, wsBooks, getItemNameFromObjectUsingCode(key,itemsForCosting));
      
      /*DevNote[ToDo]: Create Connection between parentCode(field) and  book sheet name tabe button*/
    });

    downloadExcel(workBook, costingSheetData.productTitle + "_.xlsx");
  }

  function downloadExcel(workBook, workBookName) {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const excelBuffer = XLSX.write(workBook, {
      bookType: "xlsx",
      type: "array",
    });
    const data1 = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data1, workBookName);
  }

  export  function processDataForDocument(groupedItemsForCosting,costingSheetData,itemsForCosting) {
    var tableViews=[];
    Object.keys(groupedItemsForCosting).forEach(function(key) {
      console.log("processDataForDocument_groupedItemsForCosting",groupedItemsForCosting[key]);
      console.log("processDataForDocument_getItemNameFromObjectUsingCode",getItemNameFromObjectUsingCode(key,itemsForCosting));
      tableViews.push(<>{arrayDataToTableView(groupedItemsForCosting[key],getItemNameFromObjectUsingCode(key,itemsForCosting))}</>)
      tableViews.push(<br/>);
    });
    return tableViews;
  }

  function arrayDataToTableView(dataArray,dataTitle){
    console.log("arrayDataToTableView_dataArray",dataArray);
    console.log("arrayDataToTableView_dataTitle",dataTitle);

    var largestObject={};
    var tableView=[];
    var tableDataHeadingKeys=[];
    var tableDataHeadingView=[];
    var tableBodyView=[];
    var tableBodyViewArray=[];
  
    var largestObject = dataArray.reduce((largestObject,dataObj)=>{
      return (Object.keys(dataObj).length>Object.keys(largestObject).length) ? dataObj : largestObject;
    });
      
    console.log("arrayDataToTableView_largestObject",largestObject);
    console.log("arrayDataToTableView_largestObject_length",Object.keys(largestObject).length);

    tableDataHeadingKeys=Object.keys(largestObject);

    console.log("arrayDataToTableView_tableDataHeadings",tableDataHeadingKeys);

    tableDataHeadingView.push(<thead><th colSpan={7}>{dataTitle}</th></thead>)
    tableDataHeadingView.push(<thead>{objectToTableHeadingRow(tableDataHeadingKeys)}</thead>)

    dataArray.forEach((dataObject)=>{            
      tableBodyViewArray.push(objectToTableRow(dataObject,tableDataHeadingKeys));      
    });

    tableBodyView.push(<tbody>{tableBodyView}</tbody>);
    tableView= <table width={"100%"}>{tableDataHeadingView}{tableBodyViewArray}</table>;

    return tableView;
  }

  function objectToTableHeadingRow(dataObjectKeys){
    
    var initTd=[];
    // initTd.push(<th>{"#"}</th>)
    dataObjectKeys.forEach((dataObjectKey)=>{
      console.log("arrayDataToTableView_objectToTableHeadingRow_th_push","<th>"+dataObjectKey+"</th>");
      initTd.push(<th>{dataObjectKey}</th>)
    });
    console.log("arrayDataToTableView_objectToTableHeadingRow_return","<tr>"+initTd.toString()+"</tr>");
    return <tr>{initTd}</tr>;
  }

  function objectToTableRow(dataObject,dataObjectKeys){
    console.log("arrayDataToTableView_objectToTableRow_dataObject",dataObject);
    console.log("arrayDataToTableView_objectToTableRow_dataObjectKeys",dataObjectKeys);
    
    var initTd=[];
    // initTd.push(<td>{sno}</td>)
    dataObjectKeys.forEach((dataObjectKey)=>{
      console.log("arrayDataToTableView_objectToTableRow_td_push","<td>"+dataObject[dataObjectKey]+"</td>");
      initTd.push(<td>{dataObject[dataObjectKey]}</td>)
    });
    console.log("arrayDataToTableView_objectToTableRow_return","<tr>"+initTd.toString()+"</tr>");
    return <tr>{initTd}</tr>;
  }