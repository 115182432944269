//CostingItems.js
import React, { useState, useEffect } from "react";
// import { reactLocalStorage } from "reactjs-localstorage";
import {processItemsForCostingV2, getFilteredItemsForCosting} from "../costingFuncs/CostingFunctions"

const CostingProcess = (props) => {

  console.log("CostingProcess_masterItem", props.masterItem);
  console.log("CostingProcess_itemsForCosting", props.itemsForCosting);

  var masterItem = props.masterItem;
  var itemsForCosting = props.itemsForCosting;

  console.log("CostingProcess_itemsForCosting", itemsForCosting);
  
  return (
    <>
      {/* <h3>Costing for Master Item</h3> */}
      {/* <h3 className="text-center">Tree View</h3> */}
      {/* {processItemsForCosting("cmkey_" + masterItem.code, true, masterItem)} */}
      <div style={{
        width:"50vw",
        // overflowX:"scroll"
      }}>
      {processItemsForCostingV2("cmkey_" + masterItem.code, true, masterItem,itemsForCosting,props.onEditClick,props.onDeleteClick).processedView}      
      </div>
    </>
  );
};

export default CostingProcess;
