import React from "react";
import Login from "../../components/Customer/Login";
import Navbar from "../../components/Customer/Navbar";
const CustomerLogin = () => {
  return (
    <>
      <Navbar />
      <div style={{ display: "flex", alignItems: "center", height: "100vh" }}>
        <Login />
      </div>
    </>
  );
};

export default CustomerLogin;
