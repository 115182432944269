import React from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import AdmnHome from "../../components/Admin/AdminHome";
import AdminNavbar from "../../components/Admin/AdminNavbar";
import AdminSidebar from "../../components/Admin/AdminSidebar";
// import AdminToggler from "../../components/Admin/AdminToggler";
// import MainHeader from "../../components/Customer/MainHeader";
import Footer from "../../components/Footer";

const AdminHome = () => {
  const userDataTemp = reactLocalStorage.getObject("cs_user_data");
  console.log("userDataTemp", userDataTemp);
  return (
    <>
      <AdminNavbar />
      <AdminSidebar />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">DocMaster::Costing</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">DocMaster::Costing v1</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            {/* <AdmnHome /> */}
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};
export default AdminHome;
