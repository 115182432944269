import React, { useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { apiKeyHeader } from "../../configs/ApiKeys";
import { WSGeneralUserRegistration } from "../../configs/WebService";

const SignUp = () => {
  let navigate = useNavigate();
  const [guName, setGuName] = useState("");
  const [guMobileNo, setGuMobileNo] = useState("");
  const [guEmail, setGuEmail] = useState("");
  const [guUserPassword, setGuUserPassword] = useState("");

  const [errorMsg, setErrorMsg] = useState("");

  function onGuNameChangeHandler(e) {
    setGuName(e.target.value);
  }
  function onGuMobileChangeHandler(e) {
    setGuMobileNo(e.target.value);
  }
  function onGuEmailChangeHandler(e) {
    setGuEmail(e.target.value);
  }
  function onGuPasswordChangeHandler(e) {
    setGuUserPassword(e.target.value);
  }

  function onSubmitHandler(e) {
    e.preventDefault();
    if (guUserPassword.length < 6) {
      setErrorMsg("Password length should be greater than 6");
    } else {
      setErrorMsg("");
      createGeneralUserProcess();
    }
  }

  function createGeneralUserProcess() {
    // toast.success("Submitting ...", { position: "bottom-center" });

    let formJsonParams = JSON.stringify({
      name: guName,
      mobileNo: guMobileNo,
      email: guEmail,
      password: guUserPassword,
      userType: 4,
    });

    axios
      .post(WSGeneralUserRegistration, formJsonParams, {
        headers: apiKeyHeader(),
      })
      .then((response) => {
        const responseData = response.data;
        console.log("createGeneralUserProcess_responseData", responseData);
        if (responseData.result_code == 1) {
          alert(responseData.result_message);
          //   toast.success(responseData.result_message, {
          //     position: "top-center",
          //   });
          setErrorMsg("");
          goToShowUserVerificationPage(guEmail, guMobileNo);
        } else {
          alert(responseData.result_message);
          //   toast.warn(responseData.result_message, {
          //     position: "top-center",
          //   });
          setErrorMsg(responseData.result_message);

          //   if (responseData.hasOwnProperty("contactDetails")) {
          //     goToUserVarification(responseData.contactDetails);
          //   }
        }
      })
      .catch((error) => {
        console.log("createGeneralUserProcess_error", error);
        setErrorMsg("Error while Processing");
      });
  }

  function goToShowUserVerificationPage(email, mobileNo) {
    navigate("/verifyguuserpage", {
      state: { userEmail: email, userMobile: mobileNo },
    });
  }

  return (
    <>
      <div className="login col-lg-4 col-11">
        <div className="col-12 col-lg-12 mx-auto">
          <form onSubmit={onSubmitHandler} method="POST">
            <div className="form-box px-lg-5 px-2">
              <div className="form-group mb-3">
                <label htmlFor="exampleInputEmail1" className="">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control "
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={guName}
                  onChange={onGuNameChangeHandler}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="exampleInputPassword1" className="">
                  Mobile No
                </label>
                <input
                  type="tel"
                  className="form-control "
                  id="exampleInputPassword1"
                  value={guMobileNo}
                  onChange={onGuMobileChangeHandler}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="exampleInputPassword1" className="">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control "
                  id="exampleInputPassword1"
                  value={guEmail}
                  onChange={onGuEmailChangeHandler}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label htmlFor="exampleInputPassword1" className="">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control "
                  id="exampleInputPassword1"
                  value={guUserPassword}
                  onChange={onGuPasswordChangeHandler}
                  required
                />
              </div>
              <div className="text-center">
                {/* <NavLink to="/admin"> */}
                <button type="submit" className="btn btn-info">
                  Submit
                </button>
                {/* </NavLink> */}
              </div>
            </div>
          </form>
          {errorMsg ? <div>{errorMsg}</div> : null}
        </div>
      </div>
    </>
  );
};

export default SignUp;
