//CostingFunctions.js
import React from "react";

//Return View and Calculation
export function processItemsForCostingV2(
  parentKey,
  isMasterItem,
  item,
  itemsForCosting,
  setToEdit,
  setToDelete
) {
  let totalCost = 0.0;
  let cost2cal = 0.0;
  let priceCal = 0.0;
  // var itemsMasterKey = "cmkey_" + masterItem.code;
  let viewToReturn = [];
  console.log("processItemsForCosting_view_itemsMasterKey", parentKey);

  let itemArray = getFilteredItemsForCosting(parentKey, itemsForCosting);
  console.log("processItemsForCosting_view_itemArray", itemArray);

  // calculateItemsForCosting(parentKey,isMasterItem,item);
  let editButton = <></>;
  let deleteButton = <></>;

  if (setToEdit != undefined && setToEdit != null && !isMasterItem) {
    editButton = (
      <span style={{ cursor: "pointer" }} onClick={() => setToEdit(item)}>
        <i 
        className="fa fa-edit mb-1"
        style={{fontSize:12, color:"blue"}}
        ></i>
      </span>
    );
  }

  if (setToDelete != undefined && setToDelete != null && !isMasterItem) {
    deleteButton = (
      <span style={{ cursor: "pointer",color:"red" }} onClick={() => setToDelete(item)}>
        <i 
        className="fa fa-trash mb-1"
        style={{fontSize:12, color:"red"}}
        ></i>
      </span>
    );
  }

  if (itemArray.length > 0) {
    //let costtempInit = 0.0;
    let viewToReturnTemp = [];
    let cost2calTemp = 0.0;

    itemArray.map((itemOb) => {
      // console.log("processItemsForCosting_view_itemOb", itemOb);
      // let costtemp = calculateItemsForCosting(itemOb.code, false, itemOb);
      let viewAndCostToReturnTemp = processItemsForCostingV2(
        itemOb.code,
        false,
        itemOb,
        itemsForCosting,
        setToEdit,
        setToDelete
      );
      /*
        viewToReturnTemp.push(
          <div
            style={{
              marginLeft: "20px",
              marginRight: "20px",
              paddingLeft: "20px",
              borderLeft: "2px solid #222",
            }}
          >
            Name:{itemOb.name} (Nos: {itemOb.nos}) Cost:{costtemp}
          </div>
        );
        */
      viewToReturnTemp.push(viewAndCostToReturnTemp.processedView);
      cost2calTemp = cost2calTemp + viewAndCostToReturnTemp.costCalculated;
      // costtemp= calculateItemsForCosting(itemOb.code, false, itemOb);
      // costtempInit = costtempInit + costtemp;
      // console.log("processItemsForCosting_view_cost2calTemp", cost2calTemp);
    });
    if (item.nos != undefined) {
      cost2cal = cost2calTemp * item.nos;
    } else {
      cost2cal = cost2calTemp;
    }
    priceCal = cost2calTemp;

    //cost2cal = cost2calTemp;
    console.log("processItemsForCosting_view_cost2calTemp_final_item", item);
    console.log(
      "processItemsForCosting_view_cost2calTemp_final_item.nos",
      item.nos
    );
    console.log(
      "processItemsForCosting_view_cost2calTemp_final_cost2cal",
      cost2cal
    );
    // calCost = calCostTemp*item.nos;
    // totalCost = totalCost + costtempInit;

    viewToReturn.push(
      <div
        className="initialValue"
        style={{
          marginLeft: "15px",
          // marginTop: "5px",
          // marginRight: "5px",
          paddingLeft: "3px",
          borderLeft: "1px dotted #222",
        }}
      >
        &nbsp;
        <b>
          {editButton} {deleteButton} {item.name || item.title}
        </b>
        {(item.nos && (
          <>
            {/* &nbsp;No(s): {item.nos||""} */}
            {/* &nbsp;CostPriceCalculated: {cost2calTemp} <br/> */}
            {/* &nbsp;Sub-Total <span style={{fontWeight:"bold"}}>{cost2calTemp}/-</span>&nbsp; */}
            {/* {(item.nos>0)&&<span style={{fontWeight:"bold"}}> &nbsp;({item.nos}No(s) x {cost2calTemp}(Sub-Total)) = &#8377; {cost2calTemp*item.nos}(Sub Item(s) Cost)</span>} */}
            {item.nos > 0 && (
              <span style={{ fontWeight: "bold" }}>
                {" "}
                &nbsp;({item.nos} x {cost2calTemp}) = &#8377;{" "}
                {cost2calTemp * item.nos}
              </span>
            )}
            <br />
            {/* Cost of Item(s) [No(s)*CostPriceCalculated]:<i>{item.nos*cost2calTemp||""}</i></>)||(<> Total Cost: Rs <span style={{color:'red', fontWeight:"bolder", fontSize:20}}>{cost2calTemp}/-</span></>)}       */}
          </>
        )) || (
          <>
            {" "}
            Total Cost: &#8377;{" "}
            <span style={{ color: "red", fontWeight: "bolder", fontSize: 20 }}>
              {cost2calTemp}/-
            </span>
          </>
        )}
        {viewToReturnTemp}
      </div>
    );
  } else {
    console.log("processItemsForCosting_view_NoNArrayitem", item);
    // let calCost = calculateItemsForCosting(parentKey, isMasterItem, item);
    let details = <></>;
    if (isMasterItem) {
      cost2cal = item.amount;
      priceCal = item.amount;
      details = (
        <div
          className="initialValue"
          style={{
            marginLeft: "15px",
            // marginTop: "5px",
            // marginRight: "5px",
            paddingLeft: "3px",
            borderLeft: "1px dotted #222",
          }}
        >
          {" "}
          <b>{item.title}</b> Total Cost: <i>{cost2cal}</i>
          {/* &nbsp;<i>cost2cal:{cost2cal}</i> */}
        </div>
      );
    } else {
      // cost2cal = item.price * item.nos;
      if (item.nos != undefined) {
        cost2cal = item.price * item.nos;
      } else {
        cost2cal = item.price;
      }
      priceCal = item.price;

      details = item.name;
      details = (
        <div
          className="initialValue"
          style={{
            marginLeft: "15px",
            // marginTop: "5px",
            // marginRight: "5px",
            paddingLeft: "3px",
            borderLeft: "1px dotted #222",
          }}
        >
          {/* <i><b> {item.name}</b></i> [No(s): {item.nos}] (Price: {item.price}) Cost[No(s)*Price]: <i>{cost2cal}</i> */}
          <b>
            {editButton} {deleteButton} {item.name}
          </b>{" "}
          ({item.nos} X {item.price}) = &#8377; {cost2cal}/-
          {/* &nbsp;<i>cost2cal:{cost2cal}</i> */}
        </div>
      );
    }

    // totalCost = totalCost + calCost;
    // totalCost = totalCost + calCost;

    viewToReturn.push(
      <>
        <div style={{ marginBottom: 10 }}>{details}</div>
        {/* <br /> */}
      </>
    );
  }
  // return <>{itemArray.length}</>

  /*
    if(isMasterItem){
      return (
        <div
          style={{
            marginLeft: "20px",
            marginRight: "20px",
            paddingLeft: "20px",
          }}
        >
          {viewToReturn} &nbsp; &nbsp; &nbsp; &nbsp; TotalCost: {totalCost}{" "}
        </div>
      );
    }else{
      return (
        <div
          style={{
            marginLeft: "20px",
            marginRight: "20px",
            paddingLeft: "20px",
          }}
        >
          {viewToReturn}
        </div>
      );
    }
    */
  console.log("return_totalCost_for_item", item, totalCost);
  console.log("return_totalCost_for_cost2cal_item", item, cost2cal);
  console.log("return_totalCost_for_cost2cal_price_cal", item, priceCal);

  return {
    processedView: viewToReturn,
    costCalculated: cost2cal,
    priceCalculated: priceCal,
  };
  //costCalculated:totalCost
}

export function getFilteredItemsForCosting(parentKey, itemsForCosting) {
  let itemsForCostingTempSelected = [];
  for (var i = 0; i < itemsForCosting.length; i++) {
    if (itemsForCosting[i].parentCode === parentKey) {
      itemsForCostingTempSelected.push(itemsForCosting[i]);
    }
  }
  return itemsForCostingTempSelected;
}

function calculateItemsForCosting(
  parentKey,
  isMasterItem,
  item,
  itemsForCosting
) {
  // var itemsMasterKey = "cmkey_" + masterItem.code;
  let calCost = 0.0;
  console.log("processItemsForCosting_cal_itemsMasterKey", parentKey);

  let itemArray = getFilteredItemsForCosting(parentKey, itemsForCosting);
  console.log("processItemsForCosting_cal_itemArray", itemArray);
  if (itemArray.length > 0) {
    let calCostTemp = 0.0;
    itemArray.map((itemOb) => {
      console.log("processItemsForCosting_cal_itemOb", itemOb);
      calCostTemp =
        calCostTemp +
        calculateItemsForCosting(itemOb.code, false, itemOb, itemsForCosting);
    });
    console.log("processItemsForCosting_cal_calCostTemp", calCostTemp);
    calCost = calCostTemp * item.nos;
  } else {
    if (isMasterItem) {
      calCost = item.amount;
    } else {
      calCost = item.price * item.nos;
      console.log("processItemsForCosting_cal_item", item);
      console.log("processItemsForCosting_cal_calCost12", calCost);
    }
  }

  console.log("processItemsForCosting_cal_for_item", item);
  console.log("processItemsForCosting_cal_calCost", calCost);
  // return <>{itemArray.length}</>
  return calCost;
}

export function getParentItemUsingKey(keyValue, array) {
  return array.filter(function(e) {
    return e.code == keyValue;
  });
}
