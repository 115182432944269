import React from "react"
import "../../stylesheets/BodyContainer.css"


const BodyContainer = (props) => {
    return(
        <>
        <div className="container customer_container">
            {props.children}
        </div>
        </>
    )
}
export default BodyContainer