import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { reactLocalStorage } from "reactjs-localstorage";

import { WSReadAllItemProductForCosting,WSDeleteItemProductForCosting } from "../../configs/WebService";
import { apiKeyHeader } from "../../configs/ApiKeys";
import AllFeatureDataTable from "../../GuiComponents/AllFeatureDataTable";

const ShowAllIPC = () => {
  const navigate = useNavigate();

  const userData = reactLocalStorage.getObject("cs_user_data");
  const [errorMsg, setErrorMsg] = useState("");

  const dataTableColumns = [
    {
      Header: "#",
      accessor: "sno",
    },
    {
      Header: "Product Title",
      accessor: "productTitle",
    },
    {
      Header: "Code",
      accessor: "code",
    },
    {
      Header: "Product Details",
      accessor: "productDetail",
    },
    {
      Header: "Action(s)",
      accessor: "actiontd",
    },
  ];

  const [dataTableDatas, setDataTableDatas] = useState([]);

  useEffect(() => {
    fetchProductForCosting();
  }, []);

  function fetchProductForCosting() {
    axios
      .get(WSReadAllItemProductForCosting + "?userId=" + userData.id, {
        headers: apiKeyHeader(),
      })
      .then((response) => {
        const responseData = response.data;
        console.log("responseData", responseData);
        if (responseData.result_code == 1) {          
          setDataTableDatas(set2DataTableDatas(responseData.result_message));
          setErrorMsg("");
        } else {
          setErrorMsg(responseData.result_message);
        }
      })
      .catch((error) => {
        //console.log("", "error " + error);
        setErrorMsg("Error whilte getting data");
      });
  }

  function goToCostingSheetCalculation(costingSheetData) {
    delete costingSheetData["actiontd"]; //Bug(If Not Using): Point To Be Notted
    navigate("/costingSheetCalculationProcess", {
      state: { costingSheetData: costingSheetData },
    });
  }

  function goToAddCostingSheetItems(costingSheetData) {
    delete costingSheetData["actiontd"]; //Bug(If Not Using): Point To Be Notted
    navigate("/costingMasters", {
      state: { costingSheetData: costingSheetData },
    });
  }

  function editAlert(costingSheetData){
    if(window.confirm("Sure to edit")){   
      delete costingSheetData["actiontd"]; //Bug(If Not Using): Point To Be Notted  
      navigate("/addipcosting", {
        state: { itemProductForCostingData: costingSheetData },
      });
     }
  }

  function delAlert(costingSheetData,dataArray){    
    if(window.confirm("Sure to delete")){     
     proceedToDelete(costingSheetData,dataArray);
    }
  }

  function proceedToDelete(costingSheet2Delete,dataArray){
    // console.log("proceedToDelete_costingSheet2Delete",costingSheet2Delete);

    let formJsonParams = JSON.stringify({
      userId: userData.id,
      productForCostingId: costingSheet2Delete.id
    });

    axios
      .post(WSDeleteItemProductForCosting,formJsonParams, {
        headers: apiKeyHeader(),
      })
      .then((response) => {
        const responseData = response.data;
        // console.log("responseData", responseData);
        if (responseData.result_code == 1) {
            var tempDataTables=dataArray.filter(function (csData) {
              // console.log("proceedToDelete_csData",csData);
              return costingSheet2Delete.id !== csData.id;
            });    
            // console.log("proceedToDelete_tempDataTables",tempDataTables);
            setDataTableDatas(set2DataTableDatas(tempDataTables));
        } else {
          alert(responseData.result_message);
        }
      })
      .catch((error) => {        
        alert("Error whilte getting data");
      });
  }

  function set2DataTableDatas(simpleDataArray) {
    let blankData = [];
    simpleDataArray.map((simpleData, index) => {
      simpleData["sno"] = index + 1;
      simpleData["actiontd"] = (
        <>
          {/* <a data-tip="View" title="View" key={`view${index}`} href={"/adminuserrecordpage"+"?au_id="+simpleData.id}> */}
          {/* <i
                className="fa fa-eye mx-2"
                style={{ color: "blue", cursor: "pointer" }}
                aria-hidden="true"
                onClick={()=>goToCostingSheetCalculation(simpleData)}
              ></i> */}
          {/* </a> */}
          {/* <a data-tip="docformuses" title="DocForm(s) Use" key={`docformuses${index}`}  href={"/userdocformusagerecord?au_id="+simpleData.id}> */}
          <i
            className="fa fa-eye mx-2"
            style={{ color: "blue", cursor: "pointer" }}
            aria-hidden="true"
            onClick={() => goToAddCostingSheetItems(simpleData)}
          ></i>
          {/* </a> */}
          {/* <a data-tip="Edit" title="Edit" key={`edit${index}`} > */}
          <i
                className="fa fa-pencil mx-2 "
                style={{ color: "black", cursor: "pointer" }}
                onClick={() => editAlert(simpleData)}
                aria-hidden="true"
              ></i>
          {/* </a> */}

          {/* <a data-tip="Delete" title="Delete" key={`delete${index}`} > */}
          <i
            className="fa fa-trash mx-2"
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => delAlert(simpleData,simpleDataArray)}
            aria-hidden="true"
          ></i>
          {/* </a> */}
        </>
      );
      blankData.push(simpleData);
    });

    return blankData;
  }

  return (
    <>
      {/* <ReactTooltip place="top" type="dark" effect="solid" /> */}
      {errorMsg ? (
        <div>
          <b>{errorMsg}</b>
        </div>
      ) : null}
      {(() => {
        if (errorMsg.length <= 0) {
          return (
            <div className="table-responsive">
              <AllFeatureDataTable
                columns={dataTableColumns}
                data={dataTableDatas}
              />
            </div>
          );
        }
      })()}
    </>
  );
};
export default ShowAllIPC;
