import React from "react";
import "../stylesheets/Footer.css";

const Footer = () => {
  // return(
  //     <footer className="bg-light text-center text-muted footer">
  //        <p>Copyright © 2021 DocMaster Pvt Ltd - All rights reserved</p>
  //     </footer>
  // )

  return (
    <>
      {/* <!-- /.content-wrapper --> */}
      <footer className="main-footer">
        <strong>
          <p>Copyright © 2022 DocMaster Pvt Ltd - All rights reserved</p>
        </strong>
        {/* <div class="float-right d-none d-sm-inline-block">
    </div> */}
      </footer>

      {/* <!-- Control Sidebar --> */}
      <aside className="control-sidebar control-sidebar-dark">
        {/* <!-- Control sidebar content goes here --> */}
      </aside>
      {/* <!-- /.control-sidebar --> */}
    </>
  );
};
export default Footer;
