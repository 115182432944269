import React from "react";
import "../../stylesheets/Navbar.css";
import { reactLocalStorage } from "reactjs-localstorage";

const Navbar = () => {
  document.addEventListener("scroll", () => {
    const header = document.getElementById("header");
    var scroll_position = window.scrollY;
    if (header != null) {
      if (scroll_position > 150) {
        // console.log("aman")
        header.style.backgroundColor = "black";
        header.style.padding = "10px 100px";
        // console.log(header.style)
      } else {
        header.style.backgroundColor = "transparent";
        header.style.padding = "30px 100px";
      }
    }
  });

  return (
    <>
      <header
        id="header"
        style={{ backgroundColor: "transparent", transition: "0.3s all ease" }}
      >
        <a href="#" className="logo">
          Cost Sheet
        </a>
        <ul>
          <li>
            <a href="/" className="active">
              Home
            </a>
          </li>
          <li>
            <a href="/about">About</a>
          </li>

          {(() => {
            const userDataTemp = reactLocalStorage.getObject("cs_user_data");
            if (Object.keys(userDataTemp).length == 0) {
              //console.log("Header","invalid User")
              return (
                <>
                  <li>
                    <a href="/customerlogin">Login</a>
                  </li>
                  <li>
                    <a href="/customerregistration">SignUp</a>
                  </li>
                </>
              );
            } else {
              if (userDataTemp.isLoggedIn) {
                return (
                  <li>
                    <a href="/admin">DashBoard</a>
                  </li>
                );
              } else {
                return (
                  <>
                    <li>
                      <a href="/customerlogin">Login</a>
                    </li>
                    <li>
                      <a href="/customerregistration">SignUp</a>
                    </li>
                  </>
                );
              }
            }
          })()}
        </ul>
      </header>
    </>
  );
};

export default Navbar;
