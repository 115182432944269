//CostingItems.js
import React, { useRef, useState, useEffect } from "react";
// import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ReactHTMLTableToExcel from 'react-html-to-excel';


import {
  processItemsForCostingV2,
  getFilteredItemsForCosting,
} from "../costingFuncs/CostingFunctions";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";

const CostingItemsTableView = (props) => {
  console.log("CostingItemsTableView_masterItem", props.masterItem);
  console.log("CostingItemsTableView_costItem", props.costItem);

  console.log(
    "CostingItemsTableView_itemsForCostingForThis",
    props.itemsForCostingForThis
  );
  console.log("CostingItemsTableView_itemsForCosting", props.itemsForCosting);

  var masterItem = props.masterItem;
  var itemsForCosting = props.itemsForCosting;

  console.log("CostingItemsTableView_itemsForCosting", itemsForCosting);
  const componentToPrintRef = useRef(null);
  const tableToExportRef = useRef(null);

  function viewActions(option) {
    // const userDataThis = reactLocalStorage.getObject("user_data");
    // console.log("sendDocToUser_option", option);
    // console.log("sendDocToUser_contact", contact);
    //option 0: download (after confirmed checkDocFormsLimit to process)
    //option 1: eMail
    //option 2: SMS
    //option 3: WhatsApp
    //Option 4: Print(after checkDocFormsLimit)
    //Option 5: Download(after checkDocFormsLimit)
    //Option 6: SubmitConfirmation
    var content2Print = componentToPrintRef;
    const targetComponent2Print = content2Print.current || content2Print;
    var pagesWidth = "8.3in"; //In Inches
    var pagesHeight = "11.7in"; //In Inches

    var pageTypeFormat = "a4";

    if (pageTypeFormat.toLowerCase() == "letter") {
      pagesWidth = "8.5in"; //In Inches
      pagesHeight = "11.0in"; //In Inches
    }
    if (pageTypeFormat.toLowerCase() == "legal") {
      pagesWidth = "8.5in"; //In Inches
      pagesHeight = "14.0in"; //In Inches
    }

    var setPrintMarginTop = 2;
    var setPrintMarginLeft = 4;
    var setPrintMarginBottom = 2;
    var setPrintMarginRight = 4;

    let fileName2Process = "_costsheet_Mydocmaster_" + Date.now();
    if (props.addType == "forItem") {
      fileName2Process =
        props.masterItem.title +
        "_" +
        props.costItem.name +
        "_costsheet_MyDocmaster_" +
        Date.now();
    } else {
      fileName2Process =
        props.masterItem.title + "_costsheet_MyDocmaster_" + Date.now();
    }

    if (option == 1) {
      //To Print
      let marginStye =
        "margin-right: " +
        setPrintMarginRight * 0.3937 +
        "in" +
        "; margin-left: " +
        setPrintMarginLeft * 0.3937 +
        "in" +
        "; margin-top: " +
        setPrintMarginTop * 0.3937 +
        "in" +
        "; margin-bottm: " +
        setPrintMarginBottom * 0.3937 +
        "in" +
        ";";

      let otherStye =
        "<style> @page{ size:" +
        pagesWidth +
        " " +
        pagesHeight +
        ";" +
        marginStye +
        " padding-left: 0pt; padding-right: 0pt; padding-top: 0pt; padding-bottom: 0pt; } </style>";

      var header =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
        "xmlns:w='urn:schemas-microsoft-com:office:word' " +
        "xmlns='http://www.w3.org/TR/REC-html40'>" +
        "<head><meta charset='utf-8'><title>" +
        fileName2Process +
        "</title>" +
        otherStye +
        "</head><body>";
      var footer = "</body></html>";
      let htmlString = header + targetComponent2Print.innerHTML + footer;
      htmlString2Print(htmlString);
    } else if (option == 2) {
      //Download Word

      let marginStye =
        "margin-right: " +
        setPrintMarginRight * 0.3937 +
        "in" +
        "; margin-left: " +
        setPrintMarginLeft * 0.3937 +
        "in" +
        "; margin-top: " +
        setPrintMarginTop * 0.3937 +
        "in" +
        "; margin-bottm: " +
        setPrintMarginBottom * 0.3937 +
        "in" +
        ";";

      let otherStye =
        "<style> @page{ size:" +
        pagesWidth +
        " " +
        pagesHeight +
        ";" +
        marginStye +
        " padding-left: 0pt; padding-right: 0pt; padding-top: 0pt; padding-bottom: 0pt; } </style>";

      var header =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
        "xmlns:w='urn:schemas-microsoft-com:office:word' " +
        "xmlns='http://www.w3.org/TR/REC-html40'>" +
        "<head><meta charset='utf-8'><title>" +
        fileName2Process +
        "</title>" +
        otherStye +
        "</head><body>";
      var footer = "</body></html>";
      let htmlString = header + targetComponent2Print.innerHTML + footer;

      var source =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(htmlString);
      var fileDownload = document.createElement("a");
      document.body.appendChild(fileDownload);
      fileDownload.href = source;
      fileDownload.download = fileName2Process + ".doc";
      fileDownload.click();
      document.body.removeChild(fileDownload);
    }
    //else {}
  }

  // export
  const htmlString2Print = (htmlString) => {
    // console.log("html2Print_htmlString", htmlString);
    // let htmlString2Print=processPageBreakForBackEnd(this.state.testTemplateToShow);
    // htmlString=processPageBreakForJsPrint(
    //   document.getElementById("contentToPrint").innerHTML
    // )
    //htmlString=processPageBreakForJsPrint(htmlString)
    // console.log("html2Print_htmlString",htmlString)
    var win2Print = window.open(
      "about:blank",
      "_blank",
      "width=900,height=650,toolbar=0,menubar=0,location=0"
    );
    var isChrome = Boolean(win2Print.chrome);
    var isPrinting = false;
    //win2Print.document.open();//No Need Of This
    win2Print.document.write(htmlString);
    // win2Print.document.close(); // necessary for IE >= 10 and necessary before onload for chrome //No Need Of This <- Causing Bug
    if (isChrome) {
      win2Print.onload = function() {
        // wait until all resources loaded
        isPrinting = true;
        win2Print.focus(); // necessary for IE >= 10
        win2Print.print();
        win2Print.close();
        isPrinting = false;
      };
      setTimeout(function() {
        if (!isPrinting) {
          win2Print.print();
          win2Print.close();
        }
      }, 300);
    } else {
      win2Print.document.close(); // necessary for IE >= 10
      win2Print.focus(); // necessary for IE >= 10
      win2Print.print();
      win2Print.close();
    }

    return true;
  };

  function downloadExcel() {}

  return (
    <>
      <div className="row mb-2 col-11">
        <div className="col-4 px-1">
          <button
            style={{ fontSize: 16 }}
            className="no_print btn btn-outline-primary col-12"
            onClick={() => {
              viewActions(1);
            }}
          >
            <i
              style={{ fontSize: 20 }}
              className="fa fa-print mr-2"
              aria-hidden="true"
            ></i>
            Print
          </button>
        </div>

        <div className="col-4 px-1">
          <button
            className=" btn btn-outline-dark col-12"
            onClick={() => viewActions(2)}
          >
            Download Word/Docx
          </button>
        </div>
        <div className="col-4 px-1">
          {/* <button
            className=" btn btn-outline-dark col-12"
            onClick={() => downloadExcel()}
          >
            Download Excel
          </button> */}

          <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-outline-dark col-12"
                    table="tableToExportRef"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText="Download as XLS"/>

        </div>
      </div>

      <div id="contentToPrint" ref={componentToPrintRef}>
        {(() => {
          if (props.addType == "forItem") {
            return (
              <>
                <h3>{props.masterItem.title}</h3>{" "}
                <h4>
                  <i>{props.costItem.name}</i>
                </h4>
              </>
            );
          } else {
            return (
              <h3>
                <i>{props.masterItem.title}</i>
              </h3>
            );
          }
        })()}

        <table className="table table-sm" id="tableToExportRef" ref={tableToExportRef}>
          <thead>
            <tr>
              <th>
                <Label>#</Label>
              </th>
              <th>
                <Label>Name</Label>
              </th>
              <th>
                <Label>No of Items</Label>
              </th>
              <th>
                {/* <Label title="Price">* Price</Label> */}
                <Label title="Price">Rate</Label>
              </th>
              <th className="text-right">
                {/* <Label title="Cost Calculated">*Drived Cost</Label> */}
                <Label title="Cost Calculated">Cost</Label>
              </th>
            </tr>
          </thead>
          <tbody>
            {props.itemsForCostingForThis.length > 0 &&
              props.itemsForCostingForThis.map((element, index) => {
                let itemPriceCalclation = processItemsForCostingV2(
                  element.code,
                  false,
                  element,
                  itemsForCosting,
                  null
                );
                return (
                  <tr>
                    <td>{index + 1}&nbsp;&nbsp;</td>
                    <td>{element.name}</td>
                    <td>{element.nos}</td>
                    <td>{itemPriceCalclation.priceCalculated}</td>
                    <td className="text-right">
                      {itemPriceCalclation.costCalculated}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default CostingItemsTableView;
