
import React from "react";
import "../../stylesheets/ContactUs.css";

const ContactUs = () => {
  return (
    <>
      <div className="container row mx-auto col-lg-9 col-12 contactUs">
        <div className="col-lg-4 col-12 pl-lg-5 ">
          <h4>Doc Master</h4>
          <p>A 75, Sector 63, Noida</p>

          <p>Uttar Pradesh 201301 (India)</p>

          <p>+91 98685 98685</p>

          <p>arvindscorpian@gmail.com</p>
        </div>
        <div className="col-lg-8 col-12">
          <form className="contactForm">
            <div className="form-group">
              <input
                type="email"
                className="form-control mb-3 bg-gray"
                placeholder="Name"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control mb-3 bg-gray"
                placeholder="Subject"
                id="exampleInputPassword1"
              />
            </div>
            <textarea
              className="form-control mb-3 bg-gray"
              placeholder="Message"
              id="exampleFormControlTextarea1"
              rows="5"
            ></textarea>
            <button type="submit" className="btn btn-primary mb-2">
              Submit
            </button>
          </form>
        </div>
      </div>
      <div className="col-12 mx-auto mt-5">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.3423697329554!2d77.37807331505604!3d28.619499282423007!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ceff5bb9fbf0f%3A0xdd0571024cfa62b2!2sAban%20Impex%20Private%20Limited!5e0!3m2!1sen!2sin!4v1602565824529!5m2!1sen!2sin"
          style={{ width: "100%", height: "400px", frameborder: "0" }}
          allowfullscreen=""
          aria-hidden="false"
          tabindex="0"
        ></iframe>
      </div>
    </>
  );
};
export default ContactUs;
