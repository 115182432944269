import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./App.css";

import Home from "./pages/Customer/Home";
import About from "./pages/Customer/About";
import CustomerLogin from "./pages/Customer/CustomerLogin";
import AdminLogin from "./pages/Customer/AdminLogin";
import Contact from "./pages/Customer/Contact";
import AdminHome from "./pages/Admin/AdminHome";
import CustomerRegistration from "./pages/Customer/CustomerRegistraion";
import VerifyGUPage from "./pages/Customer/VerifyGUPage";


import AllIPCostingPage from "./pages/Admin/AllIPCostingPage";
import AddItemProductCostingPage from "./pages/Admin/AddItemProductCostingPage";
import CostingMasters from "./pages/CostingMasters";
import CostingAddItems from "./pages/CostingAddItems";

function App() {
  return (
    <Suspense fallback={<>Loading...</>}>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/customerlogin" element={<CustomerLogin />} />
          <Route
            exact
            path="/customerregistration"
            element={<CustomerRegistration />}
          />
          <Route exact path="/adminlogin" element={<AdminLogin />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/verifyguuserpage" element={<VerifyGUPage />} />

          <Route exact path="/admin" element={<AdminHome />} />
          
          {/* Core Element */}
          <Route
            exact
            path="/addipcosting"
            element={<AddItemProductCostingPage />}
          />
          <Route
            exact
            path="/showallipcosting"
            element={<AllIPCostingPage />}
          />
          <Route exact path="/costingMasters" element={<CostingMasters />} />
          <Route exact path="/costingAddItems" element={<CostingAddItems />} />

        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
