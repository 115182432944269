export const webServiceGateWay="https://testapi.mydocmaster.com:8443/" //Test Server

const webServiceUser = "dc_user"
export const WSGeneralUserLogIn = webServiceGateWay + webServiceUser + "/login"
export const WSGeneralUserRegistration = webServiceGateWay + webServiceUser + "/registration"
export const WSGeneralUserVerifyOTP = webServiceGateWay + webServiceUser + "/verifyUser"

const item_product_for_costing = "dc_item_product_for_costing"
export const WSReadAllItemProductForCosting = webServiceGateWay + item_product_for_costing + "/readAllItemProductForCosting"
export const WSCreateItemProductForCosting = webServiceGateWay  + item_product_for_costing + "/createItemProductForCosting"
export const WSUpdateItemProductForCosting = webServiceGateWay  + item_product_for_costing + "/updateItemProductForCosting"
export const WSDeleteItemProductForCosting = webServiceGateWay  + item_product_for_costing + "/deleteItemProductForCosting"

//Using
const costsheetMasteAndOtherItems = "costsheet_master_n_other_items"
export const WSPutCostSheetMasterAndOtherItems = webServiceGateWay + costsheetMasteAndOtherItems + "/putCostSheetMasterAndOtherItems"
export const WSGetCostSheetMasterAndOtherItems = webServiceGateWay + costsheetMasteAndOtherItems + "/getCostSheetMasterAndOtherItems"