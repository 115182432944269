import React from "react";
import BodyContainer from "../../components/Customer/BodyContainer"
import Footer from "../../components/Footer"
import Navbar from "../../components/Customer/Navbar"
import ContactUs from "../../components/Customer/ContactUs"

const Contact = () => {
  return (
    <>
      <Navbar />
      <h3 className="aboutContainer mb-5">Contact Us</h3>
      <BodyContainer>
        <ContactUs />
      </BodyContainer>
      <Footer />
    </>
  );
};
export default Contact;
