//CostingMaster.js
import React, { useState, useEffect } from "react";
// import { createBrowserHistory } from "history";
import { useNavigate } from "react-router-dom";
import {processItemsForCostingV2} from "../costingFuncs/CostingFunctions"

const CostingMaster = (props) => {
  
  const navigate = useNavigate();
  // let history2 = createBrowserHistory({ forceRefresh: true });
  console.log("costingMaster_props_costingMasters", props.costingMasters);

  //Show Default List of CostSheet master that will be process based upon givern flow by ProductOwner
  let costingMasterDefaults=[{
    title:"BOQ",
    code:"boq_bom",
    price:0,
  }];

  const [state, setState] = useState({});
  const [disablePrice, setDisablePrice] = useState(true);
  const [itemAction, setItemAction] = useState("Add");
    let proceedToAddItems=false;
  var [currentMasterItem, setCurrentMasterItem] = useState({
    title: "",
    code: "",
    amount: 0,
  }); //To Process ItemInput
  
  /*
    `product_for_costing` bigint UNSIGNED NULL,
  `code` varchar(20) NULL,
  `title` varchar(60) NOT NULL,
  `amount` varchar(50) NOT NULL,
  `status` tinyint NOT NULL DEFAULT 0,
  `amountfromcodeproduct` varchar(20) NULL,
  */

  //   useEffect(() => {
  //     console.log(
  //       "CostingMaster_useEffect_props_costingMasters",
  //       props.costingMasters
  //     );
  //   }, [props.costingMasters]);

  function editThisItemFunc(costingMasterItem) {
    // console.log("editThisItemFunc_costingMasterItem", costingMasterItem);
    setItemAction("edit");
    setCurrentMasterItem(costingMasterItem);
  }

  function addItemForThisMaster(masterItem) {
    // console.log("CostingMaster_addItemForThisMaster_masterItem", masterItem);
    /*
    history2.push({
      pathname: "/costingAddItems",
      state: {
        masterItem: masterItem,
        costingSheetData: props.costingSheetData,
        type: "forMasterItem",
      },
    });
    */
    navigate("/costingAddItems",{state: {
      masterItem: masterItem,
      costingSheetData: props.costingSheetData,
      type: "forMasterItem",
    }})
  }

  /*
  function processCostingMastersCalculationView(costingMasters){
    let masterItemViews=[];
    let totalCost=0.00;
    costingMasters.map((masterItem) => {
      let viewAndCost=processItemsForCostingV2("cmkey_" + masterItem.code, true, masterItem,props.itemsForCosting);
      masterItemViews.push(viewAndCost.processedView);
      totalCost=totalCost+viewAndCost.costCalculated;
    })
    return <>CostSheetTotal:{totalCost} <hr/> {masterItemViews}</>;
  }
  */

  function deleteChildItem(masterElement){
    //this will be used to clear child nodes of a particular MasterItems
  }

  return (
      <table className="table table-sm">
        <tr>
          <th>#</th>
          <th>Title</th>          
          {/* <th>Calculation</th> */}
          <th>Total Cost</th>
          <th colSpan={2}>Action(s)</th>
        </tr>
        {costingMasterDefaults.length > 0 &&
          costingMasterDefaults.map((element, index) => {
            return (
              <tr>
                <td>{index + 1}&nbsp;&nbsp;</td>
                <td>{element.title}</td>
                <td>                    
                    {processItemsForCostingV2("cmkey_" + element.code, true, element,props.itemsForCosting).costCalculated}
                </td>
                <td colSpan={2}>
                  <button
                  type="button"
                    className="btn btn-sm btn-outline-primary mr-2 "
                    onClick={() => addItemForThisMaster(element)}
                    title="Add Item(s)"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                  <button
                  type="button"
                    className="btn btn-sm btn-outline-danger "
                    onClick={() => deleteChildItem(element)}
                    title="Delete Child"
                  >
                    <i class="fa fa-trash"></i>
                  </button>
                </td>
              </tr>
            );
          })}
      </table>
  );
};
export default CostingMaster;
