//CostingCore
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";

import AdminNavbar from "../components/Admin/AdminNavbar";
import AdminSidebar from "../components/Admin/AdminSidebar";
import Footer from "../components/Footer";

import CostingMaster from "../components/CostingMaster";
import CostSheetCalculation from "../components/CostSheetCalculation";

const CostingMasters = (props) => {
  
  const location = useLocation();
  var costingMasterLocalStorage = "costingMasterLocalStorage";//For Temporary Use
  var costingItemsArrayLocalStorage = "costingItemsArrayLocalStorage";//For Temporary Use
  console.log("CostingMasters_location.state", location.state);
  var costingSheetData = {};
  if (location.state !== null &&location.state !== undefined) {
    costingSheetData = location.state.costingSheetData;

    costingMasterLocalStorage = costingMasterLocalStorage + costingSheetData.id;
    costingItemsArrayLocalStorage =
      costingItemsArrayLocalStorage + costingSheetData.id;

  }

  console.log("CostingMasters_costingSheetData", costingSheetData);
  console.log(
    "CostingMasters_costingMasterLocalStorage",
    costingMasterLocalStorage
  );
  console.log(
    "CostingMasters_costingItemsArrayLocalStorage",
    costingItemsArrayLocalStorage
  );

  //   const history = useHistory();
  /*ToDo:
  Costing Sheet Title use `item_product_for_costing`,
  Costing Sheet Master,
  To Store `itemsForCostingProcess` in key value manner in db
    like id,parentKey,item in db

    ToDo: show here from master_table then onClick process further
    */

  let costingMastersTemp = reactLocalStorage.getObject(
    costingMasterLocalStorage,
    [],
    true
  );

  let itemsForCostingTemp = reactLocalStorage.getObject(
    costingItemsArrayLocalStorage,
    [],
    true
  );

  console.log("CostingMasters_costingMastersTemp", costingMastersTemp);
  console.log("CostingMasters_itemsForCostingTemp", itemsForCostingTemp);

  const [costingMasters, setCostingMasters] = useState(costingMastersTemp);
  const [itemsForCosting, setItemsForCosting] = useState(itemsForCostingTemp);
  const [costMastersChangeCount, setCostMastersChangeCount] = useState(0);

  useEffect(() => {
    // reactLocalStorage.remove(costingMasterLocalStorage);
    // reactLocalStorage.remove(costingItemsArrayLocalStorage);
    // console.log("CostingMasters_useEffect_PageLoad");
  }, []);

  useEffect(() => {
    // console.log("useEffect_2_CostingMaster_costingMasters_val", costingMasters);
    // console.log(
    //   "useEffect_2_CostingMaster_costingMasters_costMastersChangeCount",
    //   costMastersChangeCount
    // );

    if (costingMasters.length > 0) {
      reactLocalStorage.setObject(costingMasterLocalStorage, costingMasters);
    }
  }, [costingMasters, costMastersChangeCount]);

  function addCostingMaster(costingMsterItem) {
    console.log("addCostingMaster_costingMsterItem", costingMsterItem);

    let costingMastersTemp = costingMasters;
    if (isExistInCostingMaster(costingMsterItem.code)) {
      console.log("addCostingMaster_update", costingMsterItem);
      // setCostingMasters(costingMastersTemp);
      costingMastersTemp = updateCostingMasters(costingMsterItem);
      setCostingMasters(costingMastersTemp);
      setCostMastersChangeCount(costMastersChangeCount + 1);
    } else {
      console.log("addCostingMaster_add", costingMsterItem);
      costingMastersTemp.push(costingMsterItem);
      setCostingMasters(costingMastersTemp);
      setCostMastersChangeCount(costMastersChangeCount + 1);
    }
  }

  function isExistInCostingMaster(codeValue) {
    let returnValue = false;
    for (var i = 0; i < costingMasters.length; i++) {
      if (costingMasters[i].code === codeValue) {
        returnValue = true;
        break;
      }
    }
    return returnValue;
  }

  function updateCostingMasters(costingMaster) {
    let costingMastersTemp = costingMasters;
    for (var i = 0; i < costingMastersTemp.length; i++) {
      if (costingMastersTemp[i].code === costingMaster.code) {
        costingMastersTemp[i].title = costingMaster.title;
        costingMastersTemp[i].amount = costingMaster.amount;
        break;
      }
    }
    return costingMastersTemp;
  }

  return (
    <>
      <AdminNavbar />
      <AdminSidebar />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {/* <h1 className="m-0">Costing Master(s)</h1> */}
                <h1>Costing for {costingSheetData.productTitle}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  {/* <li className="breadcrumb-item active">Costing Master(s)</li> */}
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            {/* <h5>{costingSheetData.productTitle}</h5> */}
            <div className="row">
              <div className="col-sm-12">

                <CostingMaster
                  addCostingMasterFunc={addCostingMaster}
                  costingMasters={costingMasters}
                  costingSheetData={costingSheetData}
                  itemsForCosting={itemsForCosting}
                />
                
              </div>
              {/* <div className="col-sm-6">
                <CostSheetCalculation
                  costingMasters={costingMasters}
                  itemsForCosting={itemsForCosting}
                  costMastersChangeCount={costMastersChangeCount}
                />
              </div> */}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};
export default CostingMasters;
