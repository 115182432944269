import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Footer";
import Navbar from "../../components/Customer/Navbar";
import { WSGeneralUserVerifyOTP } from "../../configs/WebService";
import { apiKeyHeader } from "../../configs/ApiKeys";

const VerifyGUPage = (props) => {
  let navigate = useNavigate();

  var userEmail = "";
  var userMobile = "";
  // var fromPage = "";
  let disableEmailInput = true;
  let disableMobileInput = true;

  if (props.location.state !== undefined) {
    if (props.location.state.userEmail !== undefined) {
      userEmail = props.location.state.userEmail;
    }

    if (props.location.state.userMobile !== undefined) {
      userMobile = props.location.state.userMobile;
    }
  }

  if (userEmail.length > 5) {
    disableEmailInput = true;
  }

  if (userMobile.length >= 10) {
    disableMobileInput = true;
  }

  const [mobileNoToVerify, setMobileNoToVerify] = useState(userMobile);
  const [emailToVerify, setEmailToVerify] = useState(userEmail);
  const [mobileOtp, setMobileOtpToVerify] = useState("");
  const [emailOtp, setEmailOtpToVerify] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  function processVerifyEmail() {
    if (emailToVerify.length > 5) {
      processVerifyUser("email", emailOtp, emailToVerify);
    } else {
      setErrorMsg("Invalid eMail-id");
    }
  }

  function processVerifyMobile() {
    if (mobileNoToVerify.length >= 10) {
      processVerifyUser("mobile", mobileOtp, mobileNoToVerify);
    } else {
      setErrorMsg("Invalid Mobile");
    }
  }

  function processVerifyUser(otpType, otpText, otpSentTo) {
    // toast.success("Submitting ...", { autoClose: 600 });

    let formJsonParams = JSON.stringify({
      otpType: otpType,
      otpText: otpText,
      otpSentTo: otpSentTo,
    });

    // console.log("WSGeneralUserVerifyOTP", WSGeneralUserVerifyOTP);
    // console.log("createUserProcess", formJsonParams);
    axios
      .post(WSGeneralUserVerifyOTP, formJsonParams, { headers: apiKeyHeader() })
      .then((response) => {
        // console.log("response", response.data);
        const responseData = response.data;
        if (responseData.result_code == 1) {
          setErrorMsg("");
          setErrorMsg(responseData.result_message);

          //   if(fromPage=="userProfilePage"){
          //     goToPage();
          //   }else{
          if (otpType == "mobile") {
            // toast.success(responseData.result_message, { autoClose: 200 });
            // toast.success(
            //   "You can now proceed to login or verify eMailId for more features",
            //   { autoClose: 1000 }
            // );
            setErrorMsg(
              "You can now proceed to login or verify eMailId for more features"
            );
            //   setShowMobileVerifySection(false);
            /*
              if (fromPage == "forgetpassword") {
                goToPage();
              } else {
                if (emailToVerify == undefined || emailToVerify.length <= 4) {
                  goToPage();
                } else {
                  setShowEmailVerifySection(true);
                }
              }
              */
          } else if (otpType == "email") {
            alert("email");
            //   setShowEmailVerifySection(false);
            //   setShowMobileVerifySection(false);
            //   goToPage();
          }
          //   }
        } else {
          //   toast.warn(responseData.result_message, {
          //     position: "top-center",
          //   });
          setErrorMsg(responseData.result_message);
        }
      })
      .catch((error) => {
        console.log("processVerifyUser_error ", error);
        setErrorMsg("Error while Processing Request");
      });
  }

  return (
    <>
      {console.log(props)}
      <Navbar />
      <div style={{ display: "flex", alignItems: "center", height: "100vh" }}>
        <div className="login col-lg-4 col-11">
          <div className="col-12 col-lg-12 mx-auto">
            <form
            //   onSubmit={onSubmitHandler}
            //   method="POST"
            >
              <div className="form-box px-lg-5 px-2">
                <div className="form-group mb-3">
                  <label for="exampleInputEmail1" className="">
                    Mobile No
                  </label>
                  <div className="form-control">{mobileNoToVerify}</div>
                </div>
                <div className="form-group mb-3">
                  <label for="exampleInputEmail1" className="">
                    Email
                  </label>
                  <div className="form-control">{emailToVerify}</div>
                </div>
                <div className="form-group mb-3">
                  <label for="exampleInputPassword1" className="">
                    Mobile OTP
                  </label>

                  <div className="d-flex">
                    <div className="col-8">
                      <input
                        type="text"
                        className="form-control "
                        id="exampleInputPassword1"
                        //   value={guUserPassword}
                        //   onChange={onGuUserPasswordChangeHandler}

                        // required
                      />
                    </div>
                    <div className="col-1"></div>
                    <button
                      type="submit"
                      onClick={processVerifyMobile}
                      className="btn btn-info col-3"
                    >
                      Submit
                    </button>
                  </div>
                </div>
                {/* <div className=""> */}
                {/* <NavLink to="/admin"> */}

                {/* </NavLink> */}
                {/* </div> */}

                <div className="form-group mb-3">
                  <label for="exampleInputPassword1" className="">
                    Email OTP
                  </label>

                  <div className="d-flex">
                    <div className="col-8">
                      <input
                        type="text"
                        className="form-control "
                        id="exampleInputPassword1"
                        //   value={guUserPassword}
                        //   onChange={onGuUserPasswordChangeHandler}

                        required
                      />
                    </div>
                    <div className="col-1"></div>
                    <button
                      type="submit"
                      onClick={processVerifyEmail}
                      className="btn btn-info col-3"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              {errorMsg ? <div>{errorMsg}</div> : null}
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default VerifyGUPage;
