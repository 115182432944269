import React from "react";
import { reactLocalStorage } from "reactjs-localstorage";

const AdminNavbar = () => {
  
  function logOutAndGoToHome() {
    reactLocalStorage.remove("cs_user_data");
    reactLocalStorage.clear();
    window.localStorage.clear();
    window.location.href = "/";
  }

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      {/* <!-- Left navbar links --> */}
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="#" role="button">
            <i className="fa fa-bars"></i>
          </a>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <a href="/" className="nav-link">
            Home
          </a>
        </li>

        <li className="nav-item d-none d-sm-inline-block">
          <a href="/about" className="nav-link">
            About
          </a>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
            <a href="#" className="nav-link" onClick={logOutAndGoToHome}>
              LogOut
            </a>
          </li>
      </ul>
    </nav>
  );
};
export default AdminNavbar;
