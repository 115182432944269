import React, { useState, useEffect, Component } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { useLocation,useNavigate } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import axios from "axios";
import { apiKeyHeader } from "../../configs/ApiKeys";
// import { withRouter } from "react-router-dom";
// import { Alert } from "bootstrap/dist/js/bootstrap.bundle";
import {
  WSCreateItemProductForCosting,
  WSUpdateItemProductForCosting
} from "../../configs/WebService";

// import withRouter from "../WithRouter";
const AddItemProductForCostingForm = (props) => {
  console.log("AddItemProductForCostingForm_props",props)
  const navigate = useNavigate();
  const location = useLocation();
  const userData = reactLocalStorage.getObject("cs_user_data");

  const [productTitle, setProductTitle] = useState(
    props.itemProductForCostingData.productTitle
  );

  const [code, setCode] = useState(props.itemProductForCostingData.code);

  const [productDetail, setProductDetail] = useState(
    props.itemProductForCostingData.productDetail
  );

  const [errorMsg, setErrorMsg] = useState("");

  // changeHandler = (e) => {
  //   this.setState({ [e.target.name]: e.target.value });
  // };

  const onSubmitHandler = (e) => {
    e.preventDefault();
    createCostSheetProcess();
  };

  function createCostSheetProcess() {
    // toast.success("Submitting ...", { position: "bottom-center" });

    let WebService = WSCreateItemProductForCosting;
    let formJsonParams = JSON.stringify({//insert
      userId: userData.id,
      productTitle: productTitle,
      code: code,
      productDetail: productDetail,
    });

    if (props.action.toLowerCase() === "edit") {
      formJsonParams = JSON.stringify({//update
        userId: userData.id,
        productForCostingId: props.itemProductForCostingData.id,
        productTitle: productTitle,
        code: code,
        productDetail: productDetail,
      });
      WebService = WSUpdateItemProductForCosting;
    }

    //docmasterRawWebService//php
    //WSAdminUserCreate//Java
    console.log("createCostSheetProcess_WebService", WebService);
    console.log("createCostSheetProcess_formJsonParams", formJsonParams);
    axios
      .post(WebService, formJsonParams, {
        headers: apiKeyHeader(),
      })
      .then((response) => {
        console.log("response", response.data);
        const responseData = response.data;
        if (responseData.result_code == 1) {
          // const itemData = responseData.result_message
          // console.log("Item Data", itemData);
          //   toast.success(responseData.result_message, {
          //     position: "top-center",
          //   });
          // this.goToShowUserPage();
          goToAddCostingSheetItems({
            //create_user
            id: responseData.recordId,
            productTitle: productTitle,
            code: code,
            productDetail: productDetail,
          });
        } else {
          alert(responseData.result_message);
        }
      })
      .catch((error) => {
        console.error("error", error);
        // this.setState({ errorMsg:  });
        setErrorMsg("Error while getting data")
      });
  }

  function goToShowUserPage() {
    // console.log("gotopage")
    navigate("/showallipcosting");
  }

  function goToAddCostingSheetItems(costingSheetData) {
    delete costingSheetData["actiontd"]; //Bug(If Not Using): Point To Be Notted
    navigate("/costingMasters", {
      state: { costingSheetData: costingSheetData },
    });
  }

  // render() {
  // const { productTitle, code, productDetail, errorMsg } = this.state;
  return (
    <>
      
      <div>
        <Form
          className="px-3 py-2"
          onSubmit={onSubmitHandler}
          method="POST"
        >
          <FormGroup className="mb-3">
            <Label for="exampleEmail">Product Name</Label>
            <Input
              type="text"
              name="productTitle"
              id="productTitle"
              placeholder="Enter product name here"
              value={productTitle}
              onChange={(e) => {
                setProductTitle(e.target.value);
              }}
              required
            />
          </FormGroup>
          {/* <FormGroup className="mb-3">
            <Label for="exampleEmail">Code</Label>
            <Input
              type="text"
              name="code"
              id="code"
              placeholder="Enter Code here"
              value={code}
              onChange={this.changeHandler}
              required/>
          </FormGroup> */}
          <FormGroup className="mb-3">
            <Label for="exampleEmail">Product Detail</Label>
            <Input
              type="text"
              name="productDetail"
              id="productDetail"
              placeholder="Enter item category here"
              value={productDetail}
              onChange={(e) => {
                setProductDetail(e.target.value);
              }}
              required
            />
          </FormGroup>
          <Button className="mb-3" type="submit">
            Submit
          </Button>
          {/* {(() => {
              if (this.state.userData.isAdmin == true) {
                return (
                );
              } else {
                return (<></>);
              }
          })()} */}
        </Form>
        {errorMsg ? <div>{errorMsg}</div> : null}
      </div>
    </>
  );
  // }
};

// export default withRouter(AddItemProductForCostingForm);
export default AddItemProductForCostingForm;
