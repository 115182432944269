import React from "react";
// import BodyContainer from "../../components/Customer/BodyContainer"
// import Footer from "../../components/Footer"
import SignUp from "../../components/Customer/SignUp";
import Navbar from "../../components/Customer/Navbar";
// import { motion } from "framer-motion";
const CustomerRegistration = () => {
  return (
    <>
      <Navbar />
      <div style={{ display: "flex", alignItems: "center", height: "100vh" }}>
        <SignUp />
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default CustomerRegistration;
