import React from "react";
import BodyContainer from "../../components/Customer/BodyContainer";
import Footer from "../../components/Footer";
import Login from "../../components/Customer/Login";
import Navbar from "../../components/Customer/Navbar";

const AdminLogin = () => {
  return (
    <>
      <Navbar />
      <h3 className="aboutContainer">Admin Login Page</h3>
      <BodyContainer>
        <Login />
      </BodyContainer>
      <Footer />
    </>
  );
};

export default AdminLogin;
