import React from "react";
import AdminNavbar from "../../components/Admin/AdminNavbar";
import AdminSidebar from "../../components/Admin/AdminSidebar";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import ShowAllIPC from "../../components/Admin/ShowAllIPC";

const AllIPCostingPage = () => {
  return (
    <>
      <AdminNavbar />
      <AdminSidebar />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">
                  List of Costing Activities&nbsp;
                  <Link to="/addipcosting">
                    <button className="btn btn-danger"><i className="fa fa-plus"></i></button>
                  </Link>
                </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/">Home</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Item Product(s) for costing
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <ShowAllIPC />
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};
export default AllIPCostingPage;
