import React from "react";
import BodyContainer from "./BodyContainer";
import "../../stylesheets/About.css";
const AboutBody = () => {
  return (
    <>
    <section>
      <div className="parallax2">
          <h1 style={{color:"#2b1055"}}>About Us</h1>
      </div>
    </section>
    </>
  );
};

export default AboutBody;
