import React from "react";
import AdminNavbar from "../../components/Admin/AdminNavbar";
import AdminSidebar from "../../components/Admin/AdminSidebar";
import Footer from "../../components/Footer";
import { useLocation } from "react-router-dom";
import AddItemProductForCostingForm from "../../components/Admin/AddItemProductForCostingForm";

const AddItemProductCostingPage = () => {
  const location = useLocation();
  console.log("AddItemProductCostingPage_location.state", location.state);
  var action = "Add";
  var itemProductForCostingDataThis = {
    productTitle: "",
    code: "",
    productDetail: "",
  };
  if (location.state !== null && location.state !== undefined) {
    action = "Edit";
    itemProductForCostingDataThis = location.state.itemProductForCostingData;
  }
  return (
    <>
      <div className="adminSidebar">
        <AdminNavbar />
        <div className="wrapper">
          <AdminSidebar />
          <div className="content">
            <div className="content-wrapper">
              <div className="row">
                <div className="flex">
                  <h5>{action} Item Product for Costing</h5>
                </div>
                <div className="col-12 mx-auto p-1 blue_border">
                  <AddItemProductForCostingForm
                    itemProductForCostingData={itemProductForCostingDataThis}
                    action={action}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};
export default AddItemProductCostingPage;
