//CostingAddItems
import React, { useState, useEffect, useRef } from "react";
// import { createBrowserHistory } from "history";
import { reactLocalStorage } from "reactjs-localstorage";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { useLocation, useNavigate,NavigationType,useNavigationType } from "react-router-dom";
import axios from "axios";
import ReactToPrint from "react-to-print";
import Modal from "react-modal";

import {
  processItemsForCostingV2,
  getFilteredItemsForCosting,
  getParentItemUsingKey,
} from "../costingFuncs/CostingFunctions";

import AdminNavbar from "../components/Admin/AdminNavbar";
import AdminSidebar from "../components/Admin/AdminSidebar";
import Footer from "../components/Footer";

import CostingItems from "../components/CostingItems";
import CostingItemsTableView from "../components/CostingItemsTableView";

import {
  WSPutCostSheetMasterAndOtherItems,
  WSGetCostSheetMasterAndOtherItems,
} from "../configs/WebService";
import { apiKeyHeader } from "../configs/ApiKeys";

import {
  jsonArrayGroupBy,
  processDataForExcelExport,
  processDataForGroupCalculation,
  processDataForDocument,
} from "../otherFunctions/OtherFunctions";

const useBackButton = () => {
  const navType = useNavigationType();
  return navType === NavigationType.Pop;
};

const CostingAddItems = (props) => {
  const userData = reactLocalStorage.getObject("cs_user_data");
  const location = useLocation();

  const navigate = useNavigate();
  console.log("CostingAddItems_props", props);

  console.log("CostingAddItems_location", location);
  // let history2 = createBrowserHistory({ forceRefresh: true });
  var costingMasterLocalStorage = "costingMasterLocalStorage"; //For Temporary Use
  // var costingJSONItemsLocalStorage = "costingJSONItemsLocalStorage";
  var costingItemsArrayLocalStorage = "costingItemsArrayLocalStorage"; //For Temporary Use

  var costingSheetData = {};
  var masterItem = {};
  var itemsMasterKey = "";
  var parentKey = "";
  var addType = "";
  var costItem = {};

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenForDoc, setModalIsOpenForDoc] = useState(false);

  const [tableViewsForDoc, setTableViewsForDoc] = useState(null);

  const [allItemsForCosting, setAllItemsForCosting] = useState({});
  const [changeCount, setChangeCount] = useState(0);
  // var [selectedItem, setSelectedItem] = useState({}); //TempField

  let itemActionTemp = "Add";
  let currentItemTemp = {};
  // if (props.location.state !== undefined) {
  //   costingSheetData = props.location.state.costingSheetData;
  //   masterItem = props.location.state.masterItem;
  //   itemsMasterKey = "cmkey_" + masterItem.code;
  //   addType = props.location.state.type;

  //   /*history.push({
  //       pathname: "/costingAddItems",
  //       state: { masterItem: masterItem ,costingSheetData:props.costingSheetData,item:item,
  //       type:"forItem"},
  //     });*/
  //   parentKey = itemsMasterKey;

  //   if (addType == "forItem") {
  //     costItem = props.location.state.item;
  //     parentKey = costItem.code;
  //   }

  //   costingMasterLocalStorage = costingMasterLocalStorage + costingSheetData.id;
  //   // costingJSONItemsLocalStorage =
  //   //   costingJSONItemsLocalStorage + costingSheetData.id;
  //   costingItemsArrayLocalStorage =
  //     costingItemsArrayLocalStorage + costingSheetData.id;

  //   console.log("CostingAddItems_props.location.state", props.location.state);
  //   if (
  //     props.location.state.item2Edit != undefined &&
  //     props.location.state.item2Edit != null
  //   ) {
  //     console.log("CostingAddItems_props.location.state", "editThisItemFunc");
  //     // itemActionTemp="edit";
  //     // editThisItemFunc(props.location.state.item2Edit);//NotWorking
  //     // currentItemTemp=props.location.state.item2Edit;
  //   }
  // }

  if (location.state !== undefined) {
    costingSheetData = location.state.costingSheetData;
    masterItem = location.state.masterItem;
    itemsMasterKey = "cmkey_" + masterItem.code;
    addType = location.state.type;

    parentKey = itemsMasterKey;

    if (addType == "forItem") {
      costItem = location.state.item;
      parentKey = costItem.code;
    }

    costingMasterLocalStorage = costingMasterLocalStorage + costingSheetData.id;
    // costingJSONItemsLocalStorage =
    //   costingJSONItemsLocalStorage + costingSheetData.id;
    costingItemsArrayLocalStorage =
      costingItemsArrayLocalStorage + costingSheetData.id;

    console.log("CostingAddItems_location.state", location.state);
    if (
      location.state.item2Edit != undefined &&
      location.state.item2Edit != null
    ) {
      console.log("CostingAddItems_location.state", "editThisItemFunc");
      // itemActionTemp="edit";
      // editThisItemFunc(location.state.item2Edit);//NotWorking
      // currentItemTemp=location.state.item2Edit;
    }
  }

  const [itemAction, setItemAction] = useState(itemActionTemp);
  var [currentItem, setCurrentItem] = useState(currentItemTemp); //TempField//To Process ItemInput
  // setCurrentItem(costSheetItem);

  //ToDo: store [{key:parentKey, isMaster:true||false, subitems:[]},]

  console.log("CostingAddItems_masterItem", masterItem);
  console.log("CostingAddItems_parentKey", parentKey);
  console.log("CostingAddItems_itemsMasterKey", itemsMasterKey);
  console.log("CostingAddItems_costingSheetData", costingSheetData);
  console.log(
    "CostingAddItems_costingMasterLocalStorage",
    costingMasterLocalStorage
  );
  // console.log(
  //   "CostingAddItems_costingJSONItemsLocalStorage",
  //   costingJSONItemsLocalStorage
  // );
  console.log(
    "CostingAddItems_costingItemsArrayLocalStorage",
    costingItemsArrayLocalStorage
  );

  //   const history = useHistory();
  /*ToDo:
  Costing Sheet Title use `item_product_for_costing`,
  Costing Sheet Master,
  To Store `itemsForCosting` in array as JSON Object key value manner in db
    like id,parentKey,item in db

    ToDo: show here from master_table then onClick process further
    */

  let itemsForCostingTemp = reactLocalStorage.getObject(
    costingItemsArrayLocalStorage,
    [],
    true
  );

  console.log("CostingAddItems_itemsForCostingTemp", itemsForCostingTemp);

  const [state, setState] = useState({});
  const [disablePrice, setDisablePrice] = useState(true);
  let proceedToAddItems = false;
  // const [costingMasters, setCostingMasters] = useState(costingMastersTemp);
  const [costItemsChangeCount, setCostItemsChangeCount] = useState(0);

  // const [itemsForCostingProcess, setItemsForCostingProcess] = useState(
  //   sampleItemsToCalculateTemp
  // ); //TO Be Store in Continous JSON String

  const [itemsForCosting, setItemsForCosting] = useState(itemsForCostingTemp);

  /*
  const [itemsForCostingForThis, setItemsForCostingForThis] = useState(
    getFilteredItemsForCosting()
  );
  */

  const [itemsForCostingForThis, setItemsForCostingForThis] = useState(
    getFilteredItemsForCosting(parentKey, itemsForCostingTemp)
  );

  // window.onpopstate = function(event) {
  //   window.location.reload();
  // };

  /*
  function getFilteredItemsForCosting() {
    // itemsForCostingTemp
    console.log("CostingAddItems_parentKey", parentKey);
    let itemsForCostingTempSelected = [];
    for (var i = 0; i < itemsForCostingTemp.length; i++) {
      if (itemsForCostingTemp[i].parentCode === parentKey) {
        itemsForCostingTempSelected.push(itemsForCostingTemp[i]);
      }
    }
    console.log(
      "CostingAddItems_itemsForCostingTempSelected",
      itemsForCostingTempSelected
    );
    return itemsForCostingTempSelected;
  }
  */

  useEffect(() => {
    // reactLocalStorage.remove(costingJSONItemsLocalStorage);
    //reactLocalStorage.remove(costingMasterLocalStorage);
    //reactLocalStorage.remove(costingItemsArrayLocalStorage);
    // window.location.reload();
    console.log("useEffect_PageLoad_Remove");
    // let lastStored = reactLocalStorage.getObject(costingJSONItemsLocalStorage);
    // console.log("useEffect_lastStored", lastStored);
    // let WlLastStored = JSON.parse(window.localStorage.getItem("wl_last_stored_ifc"));
    // console.log("useEffect_WlLastStored", WlLastStored);
    getCostSheetMasterAndOtherItems();
  }, []);

  const isPop = useBackButton();

  const { pathname } = useLocation();

  // useEffect(() => {
  //   // alert("Back")
  //   console.log("isPop",isPop)
  // }, [pathname,isPop]);

  // window.addEventListener("pageshow", (event) => {
  //   const historyTraversal =
  //     event.persisted ||
  //     (typeof window.performance != "undefined" &&
  //       window.performance.navigation.type === 2);
  
  //   if (historyTraversal) {
  //     window.location.reload();
  //   }
  // });

  function getCostSheetMasterAndOtherItems() {
    console.log(
      "GetCostSheetMasterAndOtherItems_responseData_call",
      "?userId=" + userData.id + "&productForCostingId=" + costingSheetData.id
    );
    axios
      .get(
        WSGetCostSheetMasterAndOtherItems +
          "?userId=" +
          userData.id +
          "&productForCostingId=" +
          costingSheetData.id,
        {
          headers: apiKeyHeader(),
        }
      )
      .then((response) => {
        const responseData = response.data;
        console.log(
          "GetCostSheetMasterAndOtherItems_responseData",
          responseData
        );
        if (responseData.result_code == 1) {
          let itemsForCostingTemp = responseData.other_items;
          console.log("responseData_itemsForCostingTemp", itemsForCostingTemp);
          setItemsForCosting(itemsForCostingTemp);
          setItemsForCostingForThis(
            getFilteredItemsForCosting(parentKey, itemsForCostingTemp)
          );
        } else {
          //   setErrorMsg(responseData.result_message);
        }
      })
      .catch((error) => {
        console.error("GetCostSheetMasterAndOtherItems_error", error);
      });
  }

  useEffect(() => {
    console.log(
      "setItemsForCostingForThis_",
      getFilteredItemsForCosting(parentKey, itemsForCostingTemp)
    );
    setItemsForCostingForThis(
      getFilteredItemsForCosting(parentKey, itemsForCostingTemp)
    );
  }, [location.state]);

  // useEffect(() => {
  //   console.log("useEffect_2_itemsForCostingProcess", itemsForCostingProcess);
  //   console.log("useEffect_2_changeCount", changeCount);
  //   if (Object.keys(itemsForCostingProcess).length > 0) {
  //     reactLocalStorage.setObject(
  //       costingJSONItemsLocalStorage,
  //       itemsForCostingProcess
  //     );
  //   }
  // }, [itemsForCostingProcess, changeCount]);

  useEffect(() => {
    console.log("CostingAddItems_useEffect_itemsForCosting", itemsForCosting);
    //itemsForCostingProcess
    //itemsMasterKey
    //masterItem.subItems=itemsForCosting
    //initiate object like this {masteItemKey:}
    //costingItemsArrayLocalStorage
    if (itemsForCosting.length > 0) {
      reactLocalStorage.setObject(
        costingItemsArrayLocalStorage,
        itemsForCosting
      );
    }

    let masterItemTemp = masterItem;
    masterItemTemp["subItems"] = itemsForCosting;
    console.log("CostingAddItems_useEffect_masterItemTemp", masterItemTemp);

    //ToDo: store [{codeKey:parentKey, isMaster:true||false, subitems:[]},]
    //ToDo: store parentKey:{isMaster:true||false, subitems:[]}
    //allItemsForCosting
    let allItemsForCostingTemp = allItemsForCosting;
    allItemsForCosting[parentKey] = itemsForCosting;
    if (addType == "forItem") {
      allItemsForCosting["isMaster"] = false;
    } else {
      allItemsForCosting["isMaster"] = true;
    }
    console.log(
      "CostingAddItems_useEffect_allItemsForCosting",
      allItemsForCosting
    );
  }, [itemsForCosting, costItemsChangeCount]);

  /*
  function isExistInAllCostItems(parentCodeKey) {
    console.log("addCostingItem_isExistInAllCostItems_codeValue", parentCodeKey);
    let returnValue = false;
    //const [costingMasters, setCostingMasters] = useState([]);

    for (var i = 0; i < itemsForCosting.length; i++) {
      if (itemsForCosting[i].code === codeValue && itemsForCosting[i].parentCode === parentKey) {
        returnValue = true;
        break;
      }
    }

    return returnValue;
  }

  function updateAllCostingItems(allCostingItem) {
    let itemsForCostingTemp = itemsForCosting;
    for (var i = 0; i < itemsForCostingTemp.length; i++) {
      if (itemsForCostingTemp[i].code === costingItem.codee && itemsForCostingTemp[i].parentCode === parentKey) {
        itemsForCostingTemp[i].name = costingItem.name;
        itemsForCostingTemp[i].price = costingItem.price;
        break;
      }
    }
    return itemsForCostingTemp;
  }
  */

  /*
  useEffect(() => {
    console.log("useEffect_2_CostingMaster_costingMasters_val", costingMasters);
    console.log(
      "useEffect_2_CostingMaster_costingMasters_costMastersChangeCount",
      costMastersChangeCount
    );

    if (costingMasters.length > 0) {
        reactLocalStorage.setObject(costingMasterLocalStorage, costingMasters);
    }
  }, [costingMasters, costMastersChangeCount]);
  */

  function filterCostingItemJSONOBject(costItems) {
    let ttArray = Object.entries(costItems)[0];
    // ttArray.push(Object.entries(costItems)[0])
    // console.log(
    //   "filterCostingItemJSONOBject_Object.entries(costItems)[0]",
    //   Object.entries(costItems)[0]
    // );
    // console.log("filterCostingItemJSONOBject_ttArray", ttArray);
    // console.log("filterCostingItemJSONOBject_ttArray[0]", ttArray[0]);
    // console.log("filterCostingItemJSONOBject_ttArray[1]", ttArray[1]);
    let ttObject = {};
    ttObject[ttArray[0]] = ttArray[1];
    // console.log("filterCostingItemJSONOBject_ttObject", ttObject);
    return ttObject;
  }

  /*
  function addSubItems(item) {
    console.log("addSubItems_item", item);
    selectedItem = item;
    setSelectedItem(selectedItem);
    console.log("addSubItems_selectedItem", selectedItem);
    // startCostingProcess();
  }

  function editSubItems(item, parentItem) {
    console.log("editSubItems_item", item);
    console.log("editSubItems_parentItem", parentItem);
    setItemAction("edit");
    selectedItem = parentItem;
    setSelectedItem(selectedItem);
    currentItem = item;
    setCurrentItem(currentItem);
    // console.log("addSubItems_selectedItem", selectedItem);
    // startCostingProcess();
  }
  */

  /*
  function processCurrentItem() {
    let tempItemArray = {};
    console.log("processCurrentItem_selectedItem", selectedItem);
    if (selectedItem.itemCode != undefined) {
      let tempArray2 = {};
      tempItemArray = selectedItem;
      //Insert/Add Inside selectedItem
      if (tempItemArray.subitems == undefined) {
        tempItemArray["subitems"] = {};
      }
      tempItemArray["subitems"][currentItem.itemCode] = currentItem;
      tempItemArray["subitems"][currentItem.itemCode]["subitems"] = {};
      console.log("processCurrentItem_tempItemArray", tempItemArray);
      tempArray2[selectedItem.itemCode] = tempItemArray;
      console.log("processCurrentItem_tempArray2", tempArray2);
      let ttitemsForCostingProcess = Object.assign(
        itemsForCostingProcess,
        tempArray2
      );
      //let ttitemsForCostingProcess=Object.assign(itemsForCostingProcess,tempItemArray)
      //let ttitemsForCostingProcess=Object.assign({},tempArray2)
      console.log(
        "processCurrentItem_ttitemsForCostingProcess",
        ttitemsForCostingProcess
      );
      //setItemsForCostingProcess(tempArray2);
      setItemsForCostingProcess(
        filterCostingItemJSONOBject(ttitemsForCostingProcess)
      ); //filterCostingItemJSONOBject
      setChangeCount(changeCount + 1);
    } else {
      //Initiate New Fresh
      tempItemArray[currentItem.itemCode] = currentItem;
      tempItemArray[currentItem.itemCode]["subitems"] = {};
      console.log("processCurrentItem_tempItemArray", tempItemArray);
      setItemsForCostingProcess(filterCostingItemJSONOBject(tempItemArray));
      setChangeCount(changeCount + 1);
    }
    // costingProcess=tempItemArray;

    currentItem = {};
    selectedItem = {};
    setItemAction("Add");
    setSelectedItem({});
    setCurrentItem({});
    // setCostingItemModalIsOpen(false);
  }

  function editCurrentItem() {
    let tempItemArray = {};
    console.log("processCurrentItem_selectedItem", selectedItem);
    if (selectedItem.itemCode != undefined) {
      let tempArray2 = {};
      tempItemArray = selectedItem;
      //Insert/Add Inside selectedItem
      //   if(tempItemArray.subitems==undefined){
      //     tempItemArray["subitems"]={};
      //   }
      tempItemArray["subitems"][currentItem.itemCode] = currentItem;
      //   tempItemArray["subitems"][currentItem.itemCode]["subitems"]={};
      console.log("processCurrentItem_tempItemArray", tempItemArray);
      tempArray2[selectedItem.itemCode] = tempItemArray;
      console.log("processCurrentItem_tempArray2", tempArray2);
      let ttitemsForCostingProcess = Object.assign(
        itemsForCostingProcess,
        tempArray2
      );
      //let ttitemsForCostingProcess=Object.assign(itemsForCostingProcess,tempItemArray)
      //let ttitemsForCostingProcess=Object.assign({},tempArray2)
      console.log(
        "processCurrentItem_ttitemsForCostingProcess",
        ttitemsForCostingProcess
      );
      //setItemsForCostingProcess(tempArray2);
      setItemsForCostingProcess(
        filterCostingItemJSONOBject(ttitemsForCostingProcess)
      ); //filterCostingItemJSONOBject
      setChangeCount(changeCount + 1);
    } else {
      //Initiate New Fresh
      tempItemArray[currentItem.itemCode] = currentItem;
      //tempItemArray[currentItem.itemCode]["subitems"]={};
      console.log("processCurrentItem_tempItemArray", tempItemArray);
      setItemsForCostingProcess(filterCostingItemJSONOBject(tempItemArray));
      setChangeCount(changeCount + 1);
    }
    // costingProcess=tempItemArray;

    currentItem = {};
    selectedItem = {};
    setItemAction("Add");
    setSelectedItem({});
    setCurrentItem({});
    // setCostingItemModalIsOpen(false);
  }
  */

  function processThisForm(e) {
    e.preventDefault();

    addCostingItems(currentItem, itemAction);
    setItemAction("");

    let currentItemTemp = currentItem;
    setCurrentItem({});

    //clearItem2Edit();

    setDisablePrice(true);
    setState({});

    e.target.reset();
    // remarksTextAreaRef.reset();
    //DevNotes: This May coz bug if currentMasterItem not added to item array
    if (proceedToAddItems == true) {
      addItemForThisItem(currentItemTemp, null, "forItem");
    }
  }

  function clearItem2Edit() {
    // console.log("addCostingMaster_costingSheetItem", costingSheetItem);
    if (location && location.state && location.state.item2Edit) {
      //navigate(-1);
      window.history.back();
      // const stateC = { ...location.state };
      // delete stateC.item2Edit;
      // props.history.replace({ ...location, stateC });

      // console.log("cleaItem2Edit_state", stateC);
      // navigate(...location,stateC)

      // navigate("/costingAddItems",{state: {
      //   masterItem: masterItem,
      //   costingSheetData: costingSheetData,
      //   type: location.state.type,
      // }});
    }
  }

  function addCostingItems(costingSheetItem, action) {
    console.log("addCostingMaster_costingSheetItem", costingSheetItem);

    // if(action!="edit"){
    costingSheetItem["parentCode"] = parentKey;
    // }

    if (
      costingSheetItem.code == undefined ||
      costingSheetItem.code == null ||
      costingSheetItem.code.length < 2
    ) {
      console.log(
        "addCostingMaster_nocode_for",
        costingSheetItem,
        "<-->",
        Date.now()
      );
      costingSheetItem["code"] = Date.now() + "_csi";
    }

    console.log("addCostingMaster_costingSheetItem_after", costingSheetItem);

    //const [costingMasters, setCostingMasters] = useState([]);
    let allItemsForCostingTemp = itemsForCosting;
    if (isExistInCostingItems(itemsForCosting, costingSheetItem.code)) {
      console.log("addCostingItem_update", costingSheetItem);
      allItemsForCostingTemp = updateCostingMasters(
        itemsForCosting,
        costingSheetItem
      );
      //setItemsForCostingForThis(itemsForCostingTemp);
      setItemsForCosting(allItemsForCostingTemp);
      setCostItemsChangeCount(costItemsChangeCount + 1);
    } else {
      console.log("addCostingItem_add", costingSheetItem);
      allItemsForCostingTemp.push(costingSheetItem);
      setItemsForCostingForThis(itemsForCostingTemp);
      setItemsForCosting(allItemsForCostingTemp);
      //setItemsForCosting(itemsForCosting.concat(itemsForCostingTemp));
      setCostItemsChangeCount(costItemsChangeCount + 1);
    }

    let itemsForCostingTempThis = itemsForCostingForThis;
    if (isExistInCostingItems(itemsForCostingForThis, costingSheetItem.code)) {
      console.log("addCostingItem_update", costingSheetItem);
      itemsForCostingTempThis = updateCostingMasters(
        itemsForCostingForThis,
        costingSheetItem
      );
      setCostItemsChangeCount(costItemsChangeCount + 1);
    } else {
      console.log("addCostingItem_add", costingSheetItem);
      itemsForCostingTempThis.push(costingSheetItem);
      setItemsForCostingForThis(itemsForCostingTempThis);
      setCostItemsChangeCount(costItemsChangeCount + 1);
    }
  }

  function isExistInCostingItems(itemsForCostingVar, codeValue) {
    console.log("addCostingItem_isExistInCostingItems_codeValue", codeValue);
    let returnValue = false;
    //const [costingMasters, setCostingMasters] = useState([]);

    for (var i = 0; i < itemsForCostingVar.length; i++) {
      if (
        itemsForCostingVar[i].code === codeValue &&
        itemsForCostingVar[i].parentCode === parentKey
      ) {
        returnValue = true;
        break;
      }
    }

    return returnValue;
  }

  function updateCostingMasters(itemsForCostingVar, costingItem) {
    let itemsForCostingTemp = itemsForCostingVar;
    for (var i = 0; i < itemsForCostingTemp.length; i++) {
      if (
        itemsForCostingTemp[i].code === costingItem.codee &&
        itemsForCostingTemp[i].parentCode === parentKey
      ) {
        itemsForCostingTemp[i].name = costingItem.name;
        itemsForCostingTemp[i].price = costingItem.price;
        break;
      }
    }
    return itemsForCostingTemp;
  }

  function editThisItemFunc(costSheetItem) {
    console.log("editThisItemFunc_costingMasterItem", costSheetItem);
    setItemAction("edit");
    setCurrentItem(costSheetItem);
    setState(costSheetItem);
  }

  function addItemForThisItem(item, item2Edit, type) {
    //
    console.log("CostingMaster_addItemForThisMaster_masterItem", masterItem);
    console.log("CostingMaster_addItemForThisMaster_item", item);
    console.log("CostingMaster_addItemForThisMaster_item2Edit", item2Edit);
    //costingAddItems

    if (type == "forMasterItem") {
      /*//Old
      history2.push({
        pathname: "/costingAddItems",
        state: {
          masterItem: masterItem,
          costingSheetData: costingSheetData,
          type: type,
        },
      });
      */
      navigate("/costingAddItems", {
        state: {
          masterItem: masterItem,
          costingSheetData: costingSheetData,
          type: type,
        },
      });
      window.location.reload();
    } else {
      navigate("/costingAddItems", {
      state: {
        masterItem: masterItem,
        costingSheetData: costingSheetData,
        item: item,
        type: type,
        item2Edit: item2Edit,
      },
    });
    window.location.reload();
      /*Old
      history2.push({
        pathname: "/costingAddItems",
        state: {
          masterItem: masterItem,
          costingSheetData: costingSheetData,
          item: item,
          type: type,
          item2Edit: item2Edit,
        },
      });
      */

      
    }
  }

  function onEditClick(item) {
    console.log("editThisItemFunc_onEditClick_object", item);
    if (item.parentCode == undefined || item.parentCode == null) {
      alert("Can not edit this");
    } else {
      /*
      const [itemsForCosting, setItemsForCosting] = useState(itemsForCostingTemp);
    
      const [itemsForCostingForThis, setItemsForCostingForThis] = useState(
        getFilteredItemsForCosting(parentKey, itemsForCostingTemp)
      );
      */

      //editThisItemFunc(item);

      let parentItem = getParentItemUsingKey(item.parentCode, itemsForCosting);
      console.log("editThisItemFunc_onEditClick_parentItem", parentItem);
      if (parentItem.length <= 0) {
        addItemForThisItem(null, item, "forMasterItem");
      } else {
        addItemForThisItem(parentItem[0], item, "forItem");
      }
    }
  }

  function onDeleteClick(item) {
    deleteThisItemFunc(item);
  }

  function deleteThisItemFunc(costSheetItem) {
    console.log("deleteThisItemFunc_costingMasterItem", costSheetItem);
    if (
      window.confirm("Sure to Delete, Item & its Child item will get deleted")
    ) {
      //ToDo: delete element and its branch
      console.log("deleteThisItemFunc_itemsForCosting", itemsForCosting);

      let tempItemsForCosting = itemsForCosting.filter(function(
        itemForCosting
      ) {
        return (
          itemForCosting.code != costSheetItem.code &&
          itemForCosting.parentCode != costSheetItem.code
        );
      });

      console.log(
        "deleteThisItemFunc_tempItemsForCosting",
        tempItemsForCosting
      );
      setItemsForCosting(tempItemsForCosting);
      setItemsForCostingForThis(
        getFilteredItemsForCosting(parentKey, tempItemsForCosting)
      );
    }
  }

  function saveThisCostSheet() {
    /*
    reactLocalStorage.setObject(
      costingItemsArrayLocalStorage,
      itemsForCosting
    );
    */

    console.log(
      "saveThisCostSheet_costingItemsArrayLocalStorage",
      costingItemsArrayLocalStorage
    );
    console.log("saveThisCostSheet_itemsForCosting", itemsForCosting);
    console.log("saveThisCostSheet_costingSheetData", costingSheetData);

    console.log(
      "saveThisCostSheet_costingMasterLocalStorage",
      costingMasterLocalStorage
    );
    let costingMastersTemp = reactLocalStorage.getObject(
      costingMasterLocalStorage,
      [],
      true
    );
    console.log("saveThisCostSheet_costingMastersTemp", costingMastersTemp);

    /*"[FORM-DATA]
userId:number,
productForCostingId:number,
masterItems:(JSON Object Array),
otherItems:(JSON Object Array),"*/
    //putCostSheetMasterAndOtherItems

    //WSPutCostSheetMasterAndOtherItems

    const formData = new FormData();

    formData.append("userId", userData.id);
    formData.append("productForCostingId", costingSheetData.id);
    formData.append("masterItems", JSON.stringify([]));
    formData.append("otherItems", JSON.stringify(itemsForCosting));

    console.log("saveThisCostSheet_masterItems", JSON.stringify([]));
    console.log(
      "saveThisCostSheet_otherItems",
      JSON.stringify(itemsForCosting)
    );

    axios
      .post(WSPutCostSheetMasterAndOtherItems, formData, {
        headers: apiKeyHeader(),
      })
      .then((response) => {
        //console.log("", response.data);
        const responseData = response.data;
        // console.log("saveThisCostSheet_responseData", responseData);
        if (responseData.result_code === 1) {
          alert(responseData.result_message);
          //   goToPage(formId, submitForApproval, saveAndGo);
        } else {
          //   toast.warn(responseData.result_message, {
          //     autoClose: 1800,
          //   });
          alert(responseData.result_message);
        }
      })
      .catch((error) => {
        console.error("saveThisCostSheet_error", error);
        // toast.error(error, { position: "bottom-center" });
        //this.setState({ errorMsg: "Error while getting data" });
      });
  }

  const processCostSheetAllDataForExcelAndDoc=()=>{
    //let tempItemsForCosting = itemsForCosting.map((itemForCosting) => {
    return itemsForCosting.map((itemForCosting) => {
      let itemPriceCalclation = processItemsForCostingV2(
        itemForCosting.code,
        false,
        itemForCosting,
        itemsForCosting,
        null
      );
      // console.log(
      //   "downloadCompleteData_itemPriceCalclation",
      //   itemPriceCalclation
      // );
      //delete itemForCosting["code"];//DevNote[remark]:ToDelete code field in Excel Sheet
      // console.log("downloadCompleteData_itemForCosting", itemForCosting.nos);
      itemForCosting["SN"] = 1;
      itemForCosting["Name"] = itemForCosting.name;
      itemForCosting["Unit"] = itemForCosting.unit;
      itemForCosting["Qty"] = itemForCosting.nos;
      // itemForCosting["priceCalculated"] = itemPriceCalclation.priceCalculated;
      itemForCosting["Rate"] = itemPriceCalclation.priceCalculated;
      // itemForCosting["costCalculated"] = itemPriceCalclation.costCalculated;
      itemForCosting["Amount"] = itemPriceCalclation.costCalculated;
      // itemForCosting["price"]=itemPriceCalclation.priceCalculated;
      itemForCosting["Remarks"] = itemForCosting.remarks;
      return itemForCosting;
    });
  }

  function downloadCompleteData() {
    console.log("downloadCompleteData_itemsForCosting", itemsForCosting);
    
    // console.log(
    //   "downloadCompleteData_tempItemsForCosting",
    //   tempItemsForCosting
    // );
    let tempItemsForCosting = processCostSheetAllDataForExcelAndDoc();

    let itemsForCostingGroupByParentCode = jsonArrayGroupBy(
      tempItemsForCosting,
      "parentCode"
    );
    // console.log(
    //   "downloadCompleteData_itemsForCostingGroupByParentCode",
    //   itemsForCostingGroupByParentCode
    // );

    if (Object.keys(itemsForCostingGroupByParentCode).length > 0) {
      //  processDataForGroupCalculation(itemsForCostingGroupByParentCode)
      // processDataForGroupCalculation(JSON.parse(JSON.stringify(itemsForCostingGroupByParentCode)))
      // processDataForExcelExport(
      //   processDataForGroupCalculation(itemsForCostingGroupByParentCode)
      // );
      processDataForExcelExport(
        processDataForGroupCalculation(
          JSON.parse(JSON.stringify(itemsForCostingGroupByParentCode))
        ),
        costingSheetData,
        itemsForCosting
      );
    }
  }

  function downloadCompleteDataInDocOrWord() {
    console.log("downloadCompleteDataInDocOrWord_");
    /*
    let tempItemsForCosting = itemsForCosting.map((itemForCosting) => {
      let itemPriceCalclation = processItemsForCostingV2(
        itemForCosting.code,
        false,
        itemForCosting,
        itemsForCosting,
        null
      );
      // console.log(
      //   "downloadCompleteData_itemPriceCalclation",
      //   itemPriceCalclation
      // );
      //delete itemForCosting["code"];//DevNote[remark]:ToDelete code field in Excel Sheet
      itemForCosting["SN"] = 1;
      itemForCosting["Name"] = itemForCosting.name;
      itemForCosting["Unit"] = itemForCosting.unit;
      itemForCosting["Qty"] = itemForCosting.nos;
      // itemForCosting["priceCalculated"] = itemPriceCalclation.priceCalculated;
      itemForCosting["Rate"] = itemPriceCalclation.priceCalculated;
      // itemForCosting["costCalculated"] = itemPriceCalclation.costCalculated;
      itemForCosting["Amount"] = itemPriceCalclation.costCalculated;
      // itemForCosting["price"]=itemPriceCalclation.priceCalculated;
      itemForCosting["Remarks"] = itemForCosting.remarks;
      return itemForCosting;
    });*/

    let tempItemsForCosting = processCostSheetAllDataForExcelAndDoc();
    console.log(
      "downloadCompleteDataInDocOrWord_tempItemsForCosting",
      tempItemsForCosting
    );

    let itemsForCostingGroupByParentCode = jsonArrayGroupBy(
      tempItemsForCosting,
      "parentCode"
    );

    console.log(
      "downloadCompleteDataInDocOrWord_itemsForCostingGroupByParentCode",
      itemsForCostingGroupByParentCode
    );

    let dataForDoc = processDataForDocument(
      processDataForGroupCalculation(
        JSON.parse(JSON.stringify(itemsForCostingGroupByParentCode))
      ),
      costingSheetData,
      itemsForCosting
    );
    setTableViewsForDoc(dataForDoc);
    setModalIsOpenForDoc(true);
  }

  function printDoc() {
    var content2Print = componentToPrintRef;
    const targetComponent2Print = content2Print.current || content2Print;
    //document.querySelector("#filledDocFOrm")
    // console.log("targetComponent2Print_", targetComponent2Print);
    //margin: [1, 1, 1, 1],//Old
    //html2canvas: { scale: 2, dpi: 192, letterRendering: true },Old
    //jsPDF: { unit: "mm", format: "letter", orientation: "portrait" }, //Old
    var pagesWidth = "8.3in"; //In Inches
    var pagesHeight = "11.7in"; //In Inches

    var pageTypeFormat = "a4";
    //Default Top:1
    //Default Bottom:1
    //Default Right:2
    //Default Left:2

    var setPrintMarginTop = 2;
    var setPrintMarginLeft = 4;
    var setPrintMarginBottom = 2;
    var setPrintMarginRight = 4;

    //[top, left, bottom, right].
    //margin: [Number(this.state.printMarginTop), Number(this.state.printMarginLeft), Number(this.state.printMarginBottom), Number(this.state.printMarginRight)],
    let fileName2Process =
      costingSheetData.productTitle + "_MyDocMaster_CostSheet_" + Date.now();

    //Download Word

    let marginStye =
      "margin-right: " +
      setPrintMarginRight * 0.3937 +
      "in" +
      "; margin-left: " +
      setPrintMarginLeft * 0.3937 +
      "in" +
      "; margin-top: " +
      setPrintMarginTop * 0.3937 +
      "in" +
      "; margin-bottm: " +
      setPrintMarginBottom * 0.3937 +
      "in" +
      ";";

    let otherStye =
      "<style> @page{ size:" +
      pagesWidth +
      " " +
      pagesHeight +
      ";" +
      marginStye +
      " padding-left: 0pt; padding-right: 0pt; padding-top: 0pt; padding-bottom: 0pt; } </style>";

    var header =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
      "xmlns:w='urn:schemas-microsoft-com:office:word' " +
      "xmlns='http://www.w3.org/TR/REC-html40'>" +
      "<head><meta charset='utf-8'><title>" +
      fileName2Process +
      "</title>" +
      otherStye +
      "</head><body>";
    var footer = "</body></html>";
    let htmlString = header + targetComponent2Print.innerHTML + footer;

    var source =
      "data:application/vnd.ms-word;charset=utf-8," +
      encodeURIComponent(htmlString);
    var fileDownload = document.createElement("a");
    document.body.appendChild(fileDownload);
    fileDownload.href = source;
    fileDownload.download = fileName2Process + ".doc";
    fileDownload.click();
    document.body.removeChild(fileDownload);
  }

  const submitButtonRef = useRef();
  const componentToPrintRef = useRef();
  const remarksTextAreaRef = useRef();

  return (
    <>
      <AdminNavbar />
      <AdminSidebar />
      <div className="content-wrapper">
        <Modal
          transparent={false}
          ariaHideApp={false}
          isOpen={modalIsOpenForDoc}
          onRequestClose={() => setModalIsOpenForDoc(false)}
          style={{
            overlay: {
              // border: "1px solid gray",
              // width: "45vw",
              width: "100vw",
              // height: "80vh",
              // left: "27%",
              // left: 40,
              top: "0%",
              // background: "transparent",
              zIndex: 9999,
            },
            content: {
              left: "5%",
              // top: "10%",
              width: "90vw",
              // height: "fit-content"
              // height:"100%"
              // background: "transparent",
              // border: "none",
              // boxShadow:"0 0 5px black"
            },
          }}
        >
          {/* <div className="col-6"> */}
          <>
            <div className="row">
              <div className="col-md-12">
                <ReactToPrint
                  documentTitle={
                    costingSheetData.productTitle +
                    "_MyDocMaster_CostSheet_" +
                    Date.now()
                  }
                  pageStyle={
                    "@media print { body { -webkit-print-color-adjust: exact; } @page { size: " +
                    "A4" +
                    "; margin: " +
                    Number(1) * 0.3937 +
                    "in " +
                    Number(0.5) * 0.3937 +
                    "in " +
                    Number(1) * 0.3937 +
                    "in " +
                    Number(0.5) * 0.3937 +
                    "in!important }}"
                  }
                  trigger={() => {
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return (
                      <button className="btn btn-md btn-primary">
                        <i
                          style={{ fontSize: 20 }}
                          className="fa fa-print"
                          aria-hidden="true"
                        ></i>{" "}
                        Print Pdf
                      </button>
                    );
                  }}
                  content={() => componentToPrintRef.current}
                />
                &nbsp;
                <button
                  className="btn btn-md btn-primary"
                  onClick={() => printDoc()}
                >
                  <i
                    style={{ fontSize: 20 }}
                    className="fa fa-print"
                    aria-hidden="true"
                  ></i>{" "}
                  Print Doc
                </button>
              </div>
            </div>

            <div className="row" ref={componentToPrintRef}>
              <div className="col-md-12"><h2>{costingSheetData.productTitle}</h2></div>
              <div className="col-md-12"> {tableViewsForDoc}</div>
            </div>
          </>

          <button
            className="btn btn-danger"
            style={{ position: "absolute", top: 20, right: 20 }}
            onClick={() => setModalIsOpenForDoc(false)}
          >
            Close
          </button>
        </Modal>

        <div
          className="content-header"
          style={{ padding: "15px 0.5rem 0px 0.5rem" }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-6">
                {/* <h1 className="m-0">Costing Add Item(s)</h1> */}
                <h1 className="m-0">
                  List of Item(s) for {costingSheetData.productTitle}
                </h1>
              </div>
              <div className="col-sm-6 text-right">
                {/* <button
                  className="btn btn-md btn-danger mr-2"
                  onClick={() => saveThisCostSheet()}
                >
                  Save
                </button> */}
                <i
                  className="fa fa-floppy-o btn btn-md btn-primary mr-2"
                  title="Save"
                  data-tips="Save"
                  onClick={() => saveThisCostSheet()}
                  aria-hidden="true"
                ></i>
                {/* <button
                  className="btn btn-md btn-outline-danger mr-2"
                  onClick={() => setModalIsOpen(true)}
                >
                  Print
                </button> */}
                <i
                  className="fa fa-print btn btn-md btn-outline-danger mr-2"
                  title="Print"
                  data-tips="Print"
                  onClick={() => setModalIsOpen(true)}
                  aria-hidden="true"
                ></i>
                {/* <button
                  className="btn btn-md btn-outline-danger mr-2"
                  onClick={() => downloadCompleteData()}
                >
                  Download XLS
                </button> */}

                <i
                  className="fa  fa-file-excel-o btn btn-md btn-outline-danger mr-2"
                  title="Download XLS"
                  data-tips="Download XLS"
                  onClick={() => downloadCompleteData()}
                  aria-hidden="true"
                ></i>

                <i
                  className="fa  fa-file-o btn btn-md btn-outline-danger mr-2"
                  title="Download as File"
                  data-tips="Download as File"
                  onClick={() => downloadCompleteDataInDocOrWord()}
                  aria-hidden="true"
                ></i>

                {/* <button
                  className="btn btn-md btn-outline-danger "
                  onClick={() => {
                    window.history.back();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Back
                </button> */}

                <i
                  className="fa fa-arrow-left btn btn-md"
                  aria-hidden="true"
                  onClick={() => {
                    window.history.back();
                  }}
                  title="Back"
                  style={{
                    cursor: "pointer",
                    color: "rgb(223, 71, 89)",
                    border: "1px solid rgb(223, 71, 89)",
                    borderRadius: "50%",
                  }}
                ></i>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* <div className="col-6"> */}
              {/* <h2>{costingSheetData.productTitle}</h2> */}
              {/* </div> */}

              <div className="col-6 text-right">
                {/* <button
                  className="btn btn-md btn-danger mr-2"
                  onClick={() => setModalIsOpen(true) }
                >
                  Print
                </button>
                <button
                  className="btn btn-md btn-danger"
                  onClick={() => {
                    window.history.back();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Back
                </button> */}
              </div>
            </div>

            {(() => {
              if (addType == "forItem") {
                return (
                  <>
                    {/* <h3>{masterItem.title}</h3>{" "} */}
                    <div className="row">
                      <h4 className="col-6">Item Name: {costItem.name}</h4>
                      <h4 className="col-6 text-center">
                        <strong>Tree View</strong>
                      </h4>
                    </div>
                  </>
                );
              } else {
                return <h3>{/* <i>{masterItem.title}</i> */}</h3>;
              }
            })()}

            <div className="row">
              <div className="col-6">
                <Form onSubmit={processThisForm}>
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th>
                          <Label>#</Label>
                        </th>
                        <th>
                          <Label>Name</Label>
                        </th>
                        <th>Unit</th>
                        <th style={{ width: 100 }}>
                          {/* <Label>No of Items</Label> */}
                          <label>Quantity</label>
                        </th>
                        <th>
                          {/* <Label title="Price">*Price</Label> */}
                          <Label title="Price">Rate</Label>
                        </th>
                        <th className="text-right">
                          {/* <Label title="Cost Calculated">*Drived Cost</Label> */}
                          <Label title="Cost Calculated"> Cost</Label>
                        </th>
                        <th style={{ width: 130 }} className="text-right">
                          <Label>Action</Label>
                        </th>
                      </tr>
                      <tr>
                        <th colSpan={12}>
                          <Label>Remarks</Label>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>#</td>
                        <td>
                          <Input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Item Name"
                            value={currentItem.name}
                            // defaultValue={currentItem.name}
                            // value={state.name}
                            // defaultValue={state.name}
                            onChange={(e) => {
                              setState({ [e.target.name]: e.target.value }); //DevNote: Need To Render Real Time Changes
                              currentItem.name = e.target.value;
                              setCurrentItem(currentItem);
                              // console.log("currentItem_", currentItem);
                              setChangeCount(changeCount + 1);
                              // console.log("state_",state)
                            }}
                            required
                          />
                        </td>
                        <td>
                          <Input
                            type="text"
                            // autoComplete="off"
                            placeholder="Item Unit"
                            value={currentItem.unit}
                            name="unit"
                            id="unit"
                            list="unit_dl"
                            maxLength={10}
                            minLength={1}
                            onChange={(e) => {
                              setState({ [e.target.name]: e.target.value }); //DevNote: Need To Render Real Time Changes
                              currentItem.unit = e.target.value;
                              setCurrentItem(currentItem);
                              // console.log("currentItem_", currentItem);
                              setChangeCount(changeCount + 1);
                            }}
                            // readOnly={isReadOnly}
                            required
                          />
                          <datalist name="unit_dl" id="unit_dl">
                            <option key="KG" value="KG">
                              KG
                            </option>
                            <option key="Gram" value="Gram">
                              Gram
                            </option>

                            <option key="KM" value="KM">
                              KM
                            </option>

                            <option key="M" value="M">
                              M (Meter)
                            </option>

                            <option key="Ltr" value="Ltr">
                              Ltr (Litre)
                            </option>
                          </datalist>
                        </td>

                        <td>
                          <Input
                            className="hideNumberArrow"
                            type="number"
                            name="nos"
                            id="nos"
                            placeholder="No(s)"
                            value={currentItem.nos}
                            // defaultValue={currentItem.nos}
                            onChange={(e) => {
                              setState({ [e.target.name]: e.target.value }); //DevNote: Need To Render Real Time Changes
                              currentItem.nos = Number(e.target.value);
                              setCurrentItem(currentItem);
                              setChangeCount(changeCount + 1);
                              // console.log("currentItem_", currentItem);
                            }}
                            step="any"
                            required
                          />
                        </td>
                        <td>
                          {!disablePrice && (
                            <Input
                              type="number"
                              name="price"
                              id="price"
                              placeholder="price"
                              value={currentItem.price}
                              // defaultValue={currentItem.price}
                              onChange={(e) => {
                                setState({ [e.target.name]: e.target.value }); //DevNote: Need To Render Real Time Changes
                                currentItem.price = Number(e.target.value);
                                setCurrentItem(currentItem);
                                console.log("currentItem_", currentItem);
                              }}
                              step="any"
                              required
                            />
                          )}
                        </td>
                        <td></td>
                        <td className="text-right">
                          <button
                            className="btn btn-sm btn-danger "
                            type="button"
                            onClick={() => {
                              setDisablePrice(false);
                            }}
                          >
                            {/* No BreakUp */}
                            <i className="fa fa-check"></i>
                          </button>{" "}
                          <button
                            className="btn btn-sm btn-danger"
                            type="button"
                            onClick={() => {
                              setDisablePrice(true);
                              proceedToAddItems = true;
                              // submitButtonRef.click();
                              document
                                .getElementById("submitFormButton")
                                .click();
                            }}
                          >
                            {/* BreakUp */}
                            <i className="fa fa-plus"></i>
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={12}>
                          <Input
                            type="textarea"
                            name="remarks"
                            id="remarks"
                            placeholder="Remarks"
                            value={currentItem.remarks}
                            onChange={(e) => {
                              setState({ [e.target.name]: e.target.value }); //DevNote: Need To Render Real Time Changes
                              currentItem.remarks = e.target.value;
                              setCurrentItem(currentItem);
                              setChangeCount(changeCount + 1);
                              // console.log("currentItem_", currentItem);
                              // console.log("state_",state)
                            }}
                            ref={remarksTextAreaRef}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={12}>
                          {(() => {
                            if (itemAction == "edit") {
                              return (
                                <button
                                  className="btn btn-sm btn-success"
                                  style={{ width: "100%" }}
                                  type="submit"
                                  id="submitFormButton"
                                  ref={submitButtonRef}
                                >
                                  UPDATE
                                </button>
                              );
                            } else {
                              return (
                                <button
                                  className="btn btn-sm btn-primary"
                                  style={{ width: "100%" }}
                                  type="submit"
                                  id="submitFormButton"
                                  ref={submitButtonRef}
                                >
                                  ADD ITEM
                                </button>
                              );
                            }
                          })()}
                        </td>
                      </tr>
                      {itemsForCostingForThis.length > 0 &&
                        itemsForCostingForThis.map((element, index) => {
                          let itemPriceCalclation = processItemsForCostingV2(
                            element.code,
                            false,
                            element,
                            itemsForCosting
                          );
                          return (
                            <>
                              <tr>
                                <td>{index + 1}&nbsp;&nbsp;</td>
                                <td>{element.name}</td>
                                <td>{element.unit}</td>
                                <td>{element.nos}</td>
                                <td>{itemPriceCalclation.priceCalculated}</td>
                                <td className="text-right">
                                  {itemPriceCalclation.costCalculated}
                                </td>
                                <td className="text-right">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-outline-primary mr-1 "
                                    onClick={() =>
                                      addItemForThisItem(
                                        element,
                                        null,
                                        "forItem"
                                      )
                                    }
                                    title="Add Item(s)"
                                  >
                                    <i className="fa fa-plus"></i>
                                  </button>

                                  <button
                                    type="button"
                                    className="btn btn-sm btn-outline-dark mr-1"
                                    onClick={() => editThisItemFunc(element)}
                                    title="Edit"
                                  >
                                    <i className="fa fa-edit"></i>
                                  </button>

                                  <button
                                    type="button"
                                    className="btn btn-sm btn-danger mr-1"
                                    onClick={() => deleteThisItemFunc(element)}
                                    title="Delete"
                                  >
                                    <i className="fa fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan={12}>{element.remarks}</td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </Form>
              </div>

              <div
                className="col-6"
                style={{
                  backgroundColor: "white",
                  border: "1px dotted black",
                  minHeight: "70vh",
                  // maxWidth: "40vw",
                  overflowX: "scroll",
                }}
              >
                <CostingItems
                  itemsForCosting={itemsForCosting}
                  costItemsChangeCount={costItemsChangeCount}
                  masterItem={masterItem}
                  onEditClick={onEditClick}
                  onDeleteClick={onDeleteClick}
                />
              </div>
              <Modal
                transparent={false}
                ariaHideApp={false}
                // isOpen={props.addFolderModalIsOpen}
                isOpen={modalIsOpen}
                // onRequestClose={() => onCloseClick()}
                style={{
                  overlay: {
                    // border: "1px solid gray",
                    // width: "45vw",
                    width: "100vw",
                    // height: "80vh",
                    // left: "27%",
                    // left: 40,
                    top: "0%",
                    // background: "transparent",
                    zIndex: 9999,
                  },
                  content: {
                    left: "5%",
                    // top: "10%",
                    width: "90vw",
                    // height: "fit-content"
                    // height:"100%"
                    // background: "transparent",
                    // border: "none",
                    // boxShadow:"0 0 5px black"
                  },
                }}
              >
                {/* <div className="col-6"> */}
                <CostingItemsTableView
                  addType={addType}
                  masterItem={masterItem}
                  costItem={costItem}
                  itemsForCostingForThis={itemsForCostingForThis}
                  itemsForCosting={itemsForCosting}
                  costItemsChangeCount={costItemsChangeCount}
                />

                <button
                  className="btn btn-danger"
                  style={{ position: "absolute", top: 20, right: 20 }}
                  onClick={() => setModalIsOpen(false)}
                >
                  Close
                </button>
                {/* </div> */}
              </Modal>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};
export default CostingAddItems;
