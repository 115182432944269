import React, { useState,useEffect } from "react"
import { motion } from "framer-motion"
import { reactLocalStorage } from "reactjs-localstorage";
import axios from "axios";
// import { NavLink, Link, useHistory } from "react-router-dom"
import { Link, useNavigate } from "react-router-dom";
// import "./login.css"
import "../../stylesheets/Login.css"

// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

import { WSGeneralUserLogIn } from "../../configs/WebService";
import { apiKeyHeader } from "../../configs/ApiKeys";

// toast.configure();

const Login = () => {
  let navigate = useNavigate();
  const [guMobileNo, setGuMobileNo] = useState("");
  const [guUserPassword, setGuUserPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {    
    const userDataTemp = reactLocalStorage.getObject("cs_user_data");
    if (Object.keys(userDataTemp).length == 0) {
      //console.log("Header","invalid User")
    }else{
      if(userDataTemp.isLoggedIn){
        window.location.href = "/admin";
      }
      // else{
      //   console.log("Header","invalid User, not loggedin")
      // }
    }

  }, []);

  function onGuMobileChangeHandler(e) {
    setGuMobileNo(e.target.value);
  }

  function onGuUserPasswordChangeHandler(e) {
    setGuUserPassword(e.target.value);
    if (e.target.value.length < 6) {
      setErrorMsg("Enter proper Password");
    } else {
      setErrorMsg("");
    }
  }

  function onSubmitHandler(e) {
    e.preventDefault();
    generalUserLoginProcess();
  }

  function generalUserLoginProcess() {
    // console.log("Submitted")
    // console.log(guMobileNo)

    axios
      .post(WSGeneralUserLogIn, JSON.stringify({
        userid: guMobileNo,
        password: guUserPassword,
      }), {
        headers: apiKeyHeader(),
      })
      .then((response) => {
        const responseData = response.data;
        if (responseData.result_code === 1) {
          const userData = responseData.result_message;
          // console.log(userData)
          userData.isLoggedIn = true;
          // userData.isAdmin = false;
          reactLocalStorage.setObject("cs_user_data", userData);
          goToUserPage();
        } else {
          alert(responseData.result_message);
          // toast.error(responseData.result_message, {
          //   position: "top-center",
          //   autoClose: 1800,
          // });
          setErrorMsg(responseData.result_message);
          if (responseData.hasOwnProperty("contactDetails")) {
            goToUserVarification(responseData.contactDetails);
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
        setErrorMsg("Error while processing");
      });
  }

  function goToUserPage() {
    navigate("/admin");
  }

  function goToUserVarification(contactDetails) {
    navigate("verifyguuserpage",{
      state: {
        userEmail: contactDetails.email,
        userMobile: contactDetails.mobileNo,
        fromPage: "customerlogin",
      },
    });
  }

  return (
    <>
      <div
        className="login col-lg-4 col-11"
      >
        <div className="col-12 col-lg-12 mx-auto">
          <form onSubmit={onSubmitHandler} method="POST">
            <div className="form-box px-lg-5 px-2">
              <div className="form-group mb-3">
                <label for="exampleInputEmail1" className="">Login Id</label>
                <input type="text"
                  className="form-control "
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  value={guMobileNo}
                  onChange={onGuMobileChangeHandler}
                  required
                />
              </div>
              <div className="form-group mb-3">
                <label for="exampleInputPassword1" className="">Password</label>
                <input
                  type="password"
                  className="form-control "
                  id="exampleInputPassword1"
                  value={guUserPassword}
                  onChange={onGuUserPasswordChangeHandler}
                  
                  required
                />
              </div>
              <div className="form-group form-check mb-3">
                <input type="checkbox" className="form-check-input " id="exampleCheck1" />
                <label className="form-check-label" for="exampleCheck1" className="">Remember Me</label>
              </div>
              <div className="text-center">
                {/* <NavLink to="/admin"> */}
                  <button
                    type="submit"
                    className="btn btn-info"
                  >Submit</button>
                {/* </NavLink> */}
              </div>
            </div>
          </form>
        </div>
      </div>

    </>
  )
}
export default Login