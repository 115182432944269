import React from "react";
import { reactLocalStorage } from "reactjs-localstorage";
// import {Link} from "react-router-dom"
// import "../../stylesheets/AdminNavbar.css"
// import AdminToggler from "./AdminToggler";

const AdminSidebar = () => {
  const userDataTemp = reactLocalStorage.getObject("cs_user_data");
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      {/* <!-- Brand Logo --> */}
      <a href="/" className="brand-link" style={{textDecoration:"none"}}>
        {/* <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{opacity:.8}}/> */}
        <span className="brand-text font-weight-light">DocMaster :: Costing</span>
      </a>

      {/* <!-- Sidebar --> */}
      <div className="sidebar">
        {/* <!-- Sidebar user panel (optional) --> */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src="dist/img/user2-160x160.jpg"
              className="img-circle elevation-2"
              alt="User Image"
            />
          </div>
          <div className="info">
            <a href="#" className="d-block" style={{textDecoration:"none"}}>
              {userDataTemp.name}
            </a>
          </div>
        </div>

        {/* <div className="form-inline">
          <div className="input-group-append">
            <a href="/" className="d-block">
              <i className="fas fa-search fa-fw"></i>LogOut
            </a>
          </div>
        </div> */}

        {/* <!-- SidebarSearch Form --> */}
        {/* <div className="form-inline">
      <div className="input-group" data-widget="sidebar-search">
        <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search"/>
        <div className="input-group-append">
          <button className="btn btn-sidebar">
            <i className="fas fa-search fa-fw"></i>
          </button>
        </div>
      </div>
    </div> */}

        {/* <!-- Sidebar Menu --> */}
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {/* <!-- Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library --> */}
            <li className="nav-item menu-open">
              <a href="/admin" className="nav-link">
                <i className="nav-icon fa fa-tachometer"></i>
                <p>
                  Dashboard
                  {/* <i className="right fas fa-angle-left"></i> */}
                </p>
              </a>
            </li>

            {/* <li className="nav-item">
              <a href="/showallitembrands" className="nav-link">
                <i className="nav-icon fa fa-th"></i>
                <p>Item Brand</p>
              </a>
            </li>

            <li className="nav-item">
              <a href="/showallitemcategory" className="nav-link">
                <i className="nav-icon fa fa-copy"></i>
                <p>Item Category</p>
              </a>
            </li>

            <li className="nav-item">
              <a href="/showallitemproducts" className="nav-link">
                <i className="nav-icon fa fa-pie-chart"></i>
                <p>Item Product</p>
              </a>
            </li>

            <li className="nav-item">
              <a href="/showallitems" className="nav-link">
                <i className="nav-icon fa fa-tree"></i>
                <p>Items</p>
              </a>
            </li> */}
            
            <li className="nav-item">
              <a href="/showallipcosting" className="nav-link">
                <i className="nav-icon fa fa-image"></i>
                <p>Product(s) for Costing</p>
              </a>
            </li>
            
            {/* <li className="nav-item">
              <a href="/workflows" className="nav-link">
                <i className="nav-icon fa fa-image"></i>
                <p>Work/Process Flow(s)</p>
              </a>
            </li> */}

            {/* <li className="nav-item">
              <a href="/ProcessList" className="nav-link">
                <i className="nav-icon fa fa-image"></i>
                <p>Work/Process Flow V2</p>
              </a>
            </li> */}

            {/* <li className="nav-item">
            <a href="#" className="nav-link">
              <i className="nav-icon fa fa-chart-pie"></i>
              <p>
                WorkFlow
                <i className="right fa fa-angle-left"></i>
              </p>
            </a>
            <ul className="nav nav-treeview">
              <li className="nav-item">
                <a href="workflows" className="nav-link">
                  <i className="fa fa-circle nav-icon"></i>
                  <p>WorkFlows</p>
                </a>
              </li> */}
              
              {/* <li className="nav-item">
                <a href="pages/charts/flot.html" className="nav-link">
                  <i className="far fa-circle nav-icon"></i>
                  <p>Flot</p>
                </a>
              </li>
              <li className="nav-item">
                <a href="pages/charts/inline.html" className="nav-link">
                  <i className="far fa-circle nav-icon"></i>
                  <p>Inline</p>
                </a>
              </li>
              <li className="nav-item">
                <a href="pages/charts/uplot.html" className="nav-link">
                  <i className="far fa-circle nav-icon"></i>
                  <p>uPlot</p>
                </a>
              </li> */}

            {/* </ul>
          </li> */}

            {/* <li className="nav-item">
              <a href="/#" className="nav-link">
                <i className="nav-icon fa fa-edit"></i>
                <p>Product Tree(Cost Calculation)</p>
              </a>
            </li>

            <li className="nav-item">
              <a href="/costingcore" className="nav-link">
                <i className="nav-icon fa fa-edit"></i>
                <p>Costing Core</p>
              </a>
            </li> */}
            
          </ul>
        </nav>
        {/* <!-- /.sidebar-menu --> */}
      </div>
      {/* <!-- /.sidebar --> */}
    </aside>
  );
};

export default AdminSidebar;
